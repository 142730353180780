/* eslint-disable */
import { ProviderCloud as ExchangeCloud } from '@waves.exchange/provider-cloud';
import { ProviderWeb as ExchangeWeb } from '@waves.exchange/provider-web';
import { ProviderMetamask } from '@waves/provider-metamask';
import { Signer, UserData } from '@waves/signer';
import { base58Encode, base64Encode, stringToBytes } from '@waves/ts-lib-crypto';
import axios from 'axios';
import { ProviderKeeperMobile } from './provider-keeper-mobile';

import { toEggs, toEggsInt } from '$shared/domain/amounts';
import { getAddress, getAssetID } from '$shared/domain/constants';
import { ANIMAL_PREFIXES, Worlds } from '$shared/enums';
import type { ArtefactType } from '$shared/types';
import { int, stringifyFloat } from '$shared/utils';

import { getReferrerAddress } from '$/browser/localStorage';
import { getPublicKey } from '$/browser/localStorage/public-key';
import { API_URL, NODE_URL, SCAN_URL } from '$/constants';
import collectiveFarmsService from '$/services/api/collective-farm';
import {
    KEEPER_LOGIN,
    MM_LOGIN,
    MOBILE_KEEPER_LOGIN,
    WX_EMAIL_LOGIN,
    WX_WEB_LOGIN,
} from '$/store/actions/auth.actions';
import reachGoal from '$/utils/reachGoal';

import { AnimalType } from '$shared/types/animals';
import mutantsFarmingService from '../api/mutants-farming';
import AuthDataSignStore from './AuthDataSignStore';
import typeHouse from './utils/utils';

const PETE_ASSET_ID = getAssetID('PETE');
const WAVES_ASSET_ID = null;
const EGG_ASSET_ID = getAssetID('EGG');
const SPICE_ASSET_ID = getAssetID('SPICE');
const OLD_EGG_ASSET_ID = getAssetID('OLD_EGG');

const AUCTION_DAPP_ADDRESS = getAddress('AUCTION_DAPP');
const BABY_DUCKS_DAPP_ADDRESS = getAddress('BABY_DUCKS_DAPP');
const BREEDING_BABY_DUCKS_DAPP_ADDRESS = getAddress('BREEDING_BABY_DUCKS_DAPP');
const DUCK_BREEDER_DAPP_ADDRESS = getAddress('DUCK_BREEDER_DAPP');
const TURTLES_BREEDER_DAPP_ADDRESS = getAddress('TURTLES_BREEDER_DAPP');
const CANINES_BREEDER_DAPP_ADDRESS = getAddress('CANINES_BREEDER_DAPP');
const FELINES_BREEDER_DAPP_ADDRESS = getAddress('FELINES_BREEDER_DAPP');
const DUCK_FARMING_DAPP_ADDRESS = getAddress('DUCK_FARMING_DAPP');
const VEGG_FARMING_DAPP_ADDRESS = getAddress('VEGG_FARMING_DAPP');
const TURTLES_FARMING_DAPP_ADDRESS = getAddress('TURTLES_FARMING_DAPP');
const CANINES_FARMING_DAPP_ADDRESS = getAddress('CANINES_FARMING_DAPP');
const FELINES_FARMING_DAPP_ADDRESS = getAddress('FELINES_FARMING_DAPP');
const GAME_DAPP_ADDRESS = getAddress('GAME_DAPP');
const HUNT_ADDRESS = getAddress('HUNT_DAPP');
const DUCK_INCUBATOR_DAPP_ADDRESS = getAddress('DUCK_INCUBATOR_DAPP');
const LOOT_BOXES_DAPP_ADDRESS = getAddress('LOOT_BOXES_DAPP');
const WEARABLES_DAPP_ADDRESS = getAddress('WEARABLES_DAPP');
const ACCOUNT_BOOSTER_DAPP_ADDRESS = getAddress('ACCOUNT_BOOSTER_DAPP');
const MUTANT_FARMING_DAPP = getAddress('MUTANT_FARMING_DAPP');
const DUCK_HOUSE_DAPP_ADDRESS = getAddress('DUCK_HOUSE_DAPP');
const MEGA_DUCK_HOUSE_DAPP_ADDRESS = getAddress('MEGA_DUCK_HOUSE_DAPP');
const XMAS_STBLE_DAPP_ADDRESS = getAddress('XMAS_STBLE_DAPP');
const MARKETPLACE_PROXY_ADDRESS = getAddress('MARKETPLACE_PROXY');
const DUCK_REBIRTH_DAPP_ADDRESS = getAddress('DUCK_REBIRTH_DAPP');
const TURTLE_REBIRTH_DAPP_ADDRESS = getAddress('TURTLE_REBIRTH_DAPP');
const CANINE_REBIRTH_DAPP_ADDRESS = getAddress('CANINE_REBIRTH_DAPP');
const FELINE_REBIRTH_DAPP_ADDRESS = getAddress('FELINE_REBIRTH_DAPP');
const SPENCER_PROXY_DAPP_ADDRESS = getAddress('SPENCER_PROXY_DAPP');
const SWAP_DAPP_ADDRESS = getAddress('SWAP_DAPP');
const ROPE_EGG_ADDRESS = getAddress('ROPE_EGG_DAPP');
const RECHARGE_TOTAL_CAPACITY_ADDRESS = getAddress('DUCK_CAPACITY_DAPP');
const SKILL_TREE_DAPP = getAddress('SKILL_TREE_DAPP');
const SWOP_DAPP_ADDRESS = getAddress('SWOP_DAPP');
const RENT_ADDRESS = getAddress('RENT_DAPP_ADDRESS');
const USDT_ASSET_ID = getAssetID('USDT');
const AMM_DAPP_LAYER_2_ADDRESS = getAddress('AMM_DAPP_LAYER_2_ADDRESS');
const TURTLES_INCUBATOR_DAPP_ADDRESS = getAddress('TURTLES_INCUBATOR_DAPP');
const CANINES_INCUBATOR_DAPP_ADDRESS = getAddress('CANINES_INCUBATOR_DAPP');
const FELINES_INCUBATOR_DAPP_ADDRESS = getAddress('FELINES_INCUBATOR_DAPP');
const PETE_DAPP_ADDRESS = getAddress('PETE_DAPP_ADDRESS');
const MUTANT_BREEDER_DAPP_ADDRESS = getAddress('MUTANTS_BREEDER_DAPP');
const LEASE_DAPP_ADDRESS = getAddress('LEASING_ADDRESS');

const payment = (
    payments: Array<{
        assetId: assetId | null;
        amount: number;
    }>,
) => payments.filter(({ amount }) => amount > 0);

interface DappAnimal {
    dapp: string;
    asset: string | null;
    secondAsset?: string | null;
}

interface DappAnimalMap {
    [key: string]: DappAnimal;
}

class AuthenticationService {
    dappAnimalMap: DappAnimalMap = {
        [ANIMAL_PREFIXES.CANI]: {
            dapp: CANINES_INCUBATOR_DAPP_ADDRESS,
            asset: WAVES_ASSET_ID,
        },
        [ANIMAL_PREFIXES.FELI]: {
            dapp: FELINES_INCUBATOR_DAPP_ADDRESS,
            asset: PETE_ASSET_ID,
        },
    };
    dappAnimalBreeder: DappAnimalMap = {
        [ANIMAL_PREFIXES.DUCK]: {
            dapp: DUCK_BREEDER_DAPP_ADDRESS,
            asset: null,
        },
        [ANIMAL_PREFIXES.TURTLE]: {
            dapp: TURTLES_BREEDER_DAPP_ADDRESS,
            asset: null,
        },
        [ANIMAL_PREFIXES.CANI]: {
            dapp: CANINES_BREEDER_DAPP_ADDRESS,
            asset: WAVES_ASSET_ID,
        },
        [ANIMAL_PREFIXES.FELI]: {
            dapp: FELINES_BREEDER_DAPP_ADDRESS,
            asset: PETE_ASSET_ID,
        },
    };
    dappAnimalFarming: DappAnimalMap = {
        [AnimalType.CANINES]: {
            dapp: CANINES_FARMING_DAPP_ADDRESS,
            asset: WAVES_ASSET_ID,
        },
        [AnimalType.FELINES]: {
            dapp: FELINES_FARMING_DAPP_ADDRESS,
            asset: PETE_ASSET_ID,
        },
    };
    dappAnimalRebirth: DappAnimalMap = {
        [AnimalType.DUCKS]: {
            dapp: DUCK_REBIRTH_DAPP_ADDRESS,
            asset: EGG_ASSET_ID,
        },
        [AnimalType.TURTLES]: {
            dapp: TURTLE_REBIRTH_DAPP_ADDRESS,
            asset: EGG_ASSET_ID,
            secondAsset: SPICE_ASSET_ID,
        },
        [AnimalType.CANINES]: {
            dapp: CANINE_REBIRTH_DAPP_ADDRESS,
            asset: WAVES_ASSET_ID,
        },
        [AnimalType.FELINES]: {
            dapp: FELINE_REBIRTH_DAPP_ADDRESS,
            asset: PETE_ASSET_ID,
        },
    };
    collectiveFarms = {};

    signer: Record<string, Signer> = {};

    private async init(authType: string) {
        if (authType === WX_EMAIL_LOGIN && !(WX_EMAIL_LOGIN in this.signer)) {
            this.signer[WX_EMAIL_LOGIN] = new Signer({ NODE_URL });
            await this.signer[WX_EMAIL_LOGIN].setProvider(new ExchangeCloud());
            return;
        }
        if (authType === WX_WEB_LOGIN && !(WX_WEB_LOGIN in this.signer)) {
            this.signer[WX_WEB_LOGIN] = new Signer({ NODE_URL });
            await this.signer[WX_WEB_LOGIN].setProvider(new ExchangeWeb());
            return;
        }
        if (authType === MM_LOGIN && !(MM_LOGIN in this.signer)) {
            this.signer[MM_LOGIN] = new Signer({ NODE_URL });
            await this.signer[MM_LOGIN].setProvider(new ProviderMetamask());
            return;
        }
        if (authType === MOBILE_KEEPER_LOGIN && !(MOBILE_KEEPER_LOGIN in this.signer)) {
            this.signer[MOBILE_KEEPER_LOGIN] = new Signer({ NODE_URL });
            await this.signer[MOBILE_KEEPER_LOGIN].setProvider(new ProviderKeeperMobile());
            return;
        }
    }

    startWxEmailLogin = async (): Promise<UserData | void> => {
        await this.init(WX_EMAIL_LOGIN);
        return this.signer[WX_EMAIL_LOGIN].login().catch(() => {
            window.location.href = '/';
        });
    };

    startWxWebLogin = async (): Promise<UserData> => {
        await this.init(WX_WEB_LOGIN);
        return this.signer[WX_WEB_LOGIN].login();
    };

    startMMLogin = async (): Promise<UserData> => {
        await this.init(MM_LOGIN);
        return this.signer[MM_LOGIN].login();
    };

    startKeeperLogin = async (): Promise<any> => {
        if (window.WavesKeeper) {
            // @ts-expect-error no type for wavesAuth
            return window.WavesKeeper.wavesAuth();
        }
        // eslint-disable-next-line no-alert
        alert('Waves keeper is not installed.');
    };

    startMobileKeeperLogin = async (): Promise<any> => {
        await this.init(MOBILE_KEEPER_LOGIN);
        return this.signer[MOBILE_KEEPER_LOGIN].login();
    };

    logout = (authType: string): void => {
        AuthDataSignStore.remove();
        if (this.signer[authType]) {
            this.signer[authType].logout();
        }
    };

    getAccountAddress = (): string => {
        const localStorageData = window.localStorage.getItem('angle-store-key');
        if (!localStorageData) {
            return '';
        }
        const authData = JSON.parse(localStorageData).auth;
        const { address } = authData;
        return address;
    };

    getIsCollectiveFarm = async (): Promise<boolean> => {
        const address = this.getAccountAddress();
        if (this.collectiveFarms.hasOwnProperty(address)) {
            return this.collectiveFarms[address];
        }
        const isCollectiveFarm = await collectiveFarmsService.checkIsCollectiveFarm(address);
        this.collectiveFarms[address] = isCollectiveFarm;
        return isCollectiveFarm;
    };

    getTotalFee = async (feeSize: string): Promise<number | string> => {
        const additionalFeeForCollectiveFarms = 400000;
        try {
            const isCollectiveFarm = await this.getIsCollectiveFarm();
            let intFeeSize = Number(feeSize);
            if (typeof feeSize === 'string') {
                intFeeSize = parseFloat(feeSize) * 1e8;
            }
            const finalFee = isCollectiveFarm ? intFeeSize + additionalFeeForCollectiveFarms : intFeeSize;
            if (typeof feeSize === 'string') {
                return (finalFee / 1e8).toFixed(3);
            }
            return finalFee;
        } catch (e) {
            return feeSize;
        }
    };

    signAndPublishViaKeeper = async (txOptions) => {
        await this.init(KEEPER_LOGIN);
        return window.WavesKeeper.signAndPublishTransaction({
            type: 16,
            data: {
                ...txOptions,
                fee: {
                    tokens: await this.getTotalFee('0.005'),
                    assetId: 'WAVES',
                },
            },
        });
    };

    signAndPublishViaOther = async (authType: string, txOptions) => {
        await this.init(authType);
        return this.signer[authType].invoke(txOptions).broadcast();
    };

    doSign = async (authType: string, txOptions) => {
        const publicKey = getPublicKey();
        if (publicKey) {
            txOptions.senderPublicKey = base58Encode(publicKey);
        }

        const { amount: eggsAmount = 0 } = txOptions.payment.find(({ assetId }) => assetId === EGG_ASSET_ID) ?? {};
        const tx =
            authType === KEEPER_LOGIN
                ? await this.signAndPublishViaKeeper(txOptions)
                : await this.signAndPublishViaOther(authType, txOptions);

        if (eggsAmount) {
            reachGoal('SPENT_EGGS', { value: stringifyFloat(toEggs(eggsAmount), 8) });
        }

        return tx;
    };

    startHatching = async (authType, lastPrice) => {
        const txOptions = {
            dApp: DUCK_INCUBATOR_DAPP_ADDRESS,
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'startDuckHatching',
                args: [
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId: EGG_ASSET_ID,
                    amount: lastPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    signCustomData = async (authType, message) => {
        await this.init(authType);
        if (authType === KEEPER_LOGIN) {
            const bytes = stringToBytes(message);
            const encodeStr = base64Encode(bytes);
            const result = await window.WavesKeeper.signCustomData({
                version: 1,
                binary: encodeStr,
            });

            return result.signature;
        }
        return this.signer[authType].signMessage(message);
    };

    finishBreedTurtleHatching = async (authType, breedingTxId, extraFee) => {
        const txOptions = {
            dApp: TURTLES_BREEDER_DAPP_ADDRESS,
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'finishTRTLHatching',
                args: [
                    {
                        type: 'string',
                        value: breedingTxId,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    finishTurtleHatching = async (authType, hatchingTxId, extraFee) => {
        const txOptions = {
            dApp: TURTLES_INCUBATOR_DAPP_ADDRESS,
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'finishTRTLHatching',
                args: [
                    {
                        type: 'string',
                        value: hatchingTxId,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    startMutantHatching = async (authType, duckId, turtleId, spicePrice, eggPrice, extraFee) => {
        const txOptions = {
            dApp: MUTANT_BREEDER_DAPP_ADDRESS,

            call: {
                function: 'startMutantHatching',
                args: [
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: [
                {
                    assetId: duckId,
                    amount: 1,
                },
                {
                    assetId: turtleId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: SPICE_ASSET_ID,
                    amount: spicePrice,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: eggPrice,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    finishMutantHatching = async (authType, hatchingTxId, extraFee) => {
        const txOptions = {
            dApp: MUTANT_BREEDER_DAPP_ADDRESS,

            call: {
                function: 'finishMutantHatching',
                args: [
                    {
                        type: 'string',
                        value: hatchingTxId,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    finishHatching = async (authType, hatchingTxId, extraFee) => {
        const txOptions = {
            dApp: DUCK_INCUBATOR_DAPP_ADDRESS,
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'finishDuckHatching',
                args: [
                    {
                        type: 'string',
                        value: hatchingTxId,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    claimPete = async (authType) => {
        const txOptions = {
            dApp: PETE_DAPP_ADDRESS,
            call: {
                function: 'claim',
                args: [],
            },
            payment: [],
        };

        return this.doSign(authType, txOptions);
    };

    stakePete = async (authType, amount) => {
        const txOptions = {
            dApp: PETE_DAPP_ADDRESS,
            call: {
                function: 'stake',
                args: [],
            },
            payment: [
                {
                    assetId: PETE_ASSET_ID,
                    amount: amount,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    unstakePete = async (authType, amount) => {
        const txOptions = {
            dApp: PETE_DAPP_ADDRESS,
            call: {
                function: 'unstake',
                args: [
                    {
                        type: 'integer',
                        value: amount,
                    },
                ],
            },
            payment: [],
        };

        return this.doSign(authType, txOptions);
    };

    stakeNFTPete = async (authType, assetId) => {
        const txOptions = {
            dApp: PETE_DAPP_ADDRESS,
            call: {
                function: 'stakeNFT',
                args: [],
            },
            payment: [
                {
                    assetId: assetId,
                    amount: 1,
                },
            ],
        };
        return this.doSign(authType, txOptions);
    };

    unstakeNFTPete = async (authType, peteId) => {
        const txOptions = {
            dApp: PETE_DAPP_ADDRESS,
            call: {
                function: 'unstakeNFT',
                args: [
                    {
                        type: 'string',
                        value: peteId,
                    },
                ],
            },
            payment: [],
        };
        return this.doSign(authType, txOptions);
    };

    transferEggs = async (authType, recipient: addressId, amount: number) => {
        await this.init(authType);
        const txOptions = {
            recipient,
            amount: toEggsInt(amount),
            // fee: await this.getTotalFee(100000),
            assetId: EGG_ASSET_ID,
        };

        if (authType === KEEPER_LOGIN) {
            return window.WavesKeeper.signAndPublishTransaction({
                type: 4,
                data: {
                    amount: {
                        assetId: EGG_ASSET_ID,
                        tokens: amount.toString(),
                    },
                    recipient: txOptions.recipient,
                    fee: {
                        tokens: await this.getTotalFee('0.001'),
                        assetId: 'WAVES',
                    },
                },
            });
        }
        return this.signer[authType].transfer(txOptions).broadcast();
    };

    ropeEgg = async (
        authType,
        eggId: string,
        amount: number,
        distance: number,
        signature: string,
        discountedPrice: integer = int(0),
    ) => {
        let dappArgs = [
            { type: 'string', value: eggId },
            { type: 'integer', value: amount },
            { type: 'integer', value: distance },
            { type: 'string', value: signature },
        ];
        let dappAddress = ROPE_EGG_ADDRESS;
        let functionToCall = 'buyRopeInverted';

        const payments = [
            {
                assetId: WAVES_ASSET_ID,
                amount: amount,
            },
            {
                assetId: EGG_ASSET_ID,
                amount: discountedPrice,
            },
        ];

        if (discountedPrice === 0) {
            payments.pop();
        }

        const txOptions = {
            dApp: dappAddress,
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: payment(payments),
        };

        return this.doSign(authType, txOptions);
    };

    useBooster = async (authType, boosterId: string, extraFee) => {
        let dappArgs = [];
        let dappAddress = ROPE_EGG_ADDRESS;
        let functionToCall = 'useBooster';

        const payments = [
            {
                assetId: WAVES_ASSET_ID,
                amount: extraFee,
            },
            {
                assetId: boosterId,
                amount: 1,
            },
        ];

        const txOptions = {
            dApp: dappAddress,
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: payment(payments),
        };

        return this.doSign(authType, txOptions);
    };

    addCapacityToDuckInHunt = async (authType, duckId: string, addedCapacity: number, amount: number, extraFee) => {
        let dappArgs = [
            { type: 'string', value: duckId },
            { type: 'integer', value: addedCapacity },
            {
                type: 'string',
                value: await getReferrerAddress(),
            },
        ];
        let dappAddress = RECHARGE_TOTAL_CAPACITY_ADDRESS;
        let functionToCall = 'addCapacityToDuckInHunt';

        const txOptions = {
            dApp: dappAddress,
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    addCapacityToDuck = async (authType, assetId, addedCapacity: number, amount, extraFee) => {
        let dappArgs = [
            { type: 'integer', value: addedCapacity },
            {
                type: 'string',
                value: await getReferrerAddress(),
            },
        ];
        let dappAddress = RECHARGE_TOTAL_CAPACITY_ADDRESS;
        let functionToCall = 'addCapacityToDuck';
        let payments = [
            {
                assetId,
                amount: 1,
            },
            {
                assetId: WAVES_ASSET_ID,
                amount: extraFee,
            },
        ];
        if (amount > 0) {
            payments.push({
                assetId: EGG_ASSET_ID,
                amount,
            });
        }

        const txOptions = {
            dApp: dappAddress,
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: payments,
        };

        return this.doSign(authType, txOptions);
    };

    transferNft = async (authType, recipient, nftId) => {
        await this.init(authType);
        const txOptions = {
            recipient,
            amount: 1,
            // fee: await this.getTotalFee(100000),
            assetId: nftId,
        };

        if (authType === KEEPER_LOGIN) {
            return window.WavesKeeper.signAndPublishTransaction({
                type: 4,
                data: {
                    amount: {
                        assetId: nftId,
                        tokens: '1',
                    },
                    recipient: txOptions.recipient,
                    fee: {
                        tokens: await this.getTotalFee('0.001'),
                        assetId: 'WAVES',
                    },
                },
            });
        }
        return this.signer[authType].transfer(txOptions).broadcast();
    };

    initAuction = async (
        authType,
        nftId: nftId,
        params: { initialPrice: number; instantPrice: number; comment: string; isTurtle?: boolean },
    ) => {
        let dappArgs = [
            { type: 'integer', value: params.initialPrice },
            { type: 'integer', value: params.instantPrice },
            { type: 'string', value: params.comment },
            { type: 'string', value: 'false' },
        ];
        let dappAddress = AUCTION_DAPP_ADDRESS;
        let functionToCall = 'initAuction';

        if (await this.getIsCollectiveFarm()) {
            const marketSignData = await this.getMarketSignData(nftId, params.isTurtle);

            dappAddress = MARKETPLACE_PROXY_ADDRESS;
            dappArgs = [
                { type: 'string', value: functionToCall },
                ...dappArgs,
                { type: 'boolean', value: marketSignData.achievement },
                { type: 'boolean', value: marketSignData.canbreed },
                { type: 'string', value: marketSignData.genes },
                { type: 'integer', value: marketSignData.price },
                { type: 'integer', value: marketSignData.rarity },
                { type: 'integer', value: marketSignData.timestamp },
                { type: 'string', value: marketSignData.signature },
            ];
            functionToCall = 'callMarketplaceProxy';
        }

        const txOptions = {
            dApp: dappAddress,
            // fee: await this.getTotalFee(500000),
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: payment([
                {
                    assetId: nftId,
                    amount: 1,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    instantBuy = async (
        authType,
        auctionId: auctionId,
        nftId: nftId,
        instantPrice: number,
        eggSell: boolean,
        isTurtle?: boolean,
        isMutant?: boolean,
    ) => {
        let dappArgs = [{ type: 'string', value: auctionId }];
        let dappAddress = AUCTION_DAPP_ADDRESS;
        let functionToCall = 'instantBuy';

        if (await this.getIsCollectiveFarm()) {
            //TODO
            const marketSignData = await this.getMarketSignData(nftId, isTurtle, isMutant);

            dappAddress = MARKETPLACE_PROXY_ADDRESS;
            dappArgs = [
                { type: 'string', value: functionToCall },
                { type: 'integer', value: 0 },
                { type: 'integer', value: 0 },
                dappArgs[0],
                { type: 'string', value: '' },
                { type: 'boolean', value: marketSignData.achievement },
                { type: 'boolean', value: marketSignData.canbreed },
                { type: 'string', value: marketSignData.genes },
                { type: 'integer', value: marketSignData.price },
                { type: 'integer', value: marketSignData.rarity },
                { type: 'integer', value: marketSignData.timestamp },
                { type: 'string', value: marketSignData.signature },
            ];
            functionToCall = 'callMarketplaceProxy';
        }
        const txOptions = {
            dApp: dappAddress,
            // fee: await this.getTotalFee(500000),
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: payment([
                {
                    assetId: eggSell ? EGG_ASSET_ID : null,
                    amount: instantPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    cancelAuction = async (authType, auctionId) => {
        let dappArgs = [{ type: 'string', value: auctionId }];
        let dappAddress = AUCTION_DAPP_ADDRESS;
        let functionToCall = 'cancelAuction';

        if (await this.getIsCollectiveFarm()) {
            dappAddress = MARKETPLACE_PROXY_ADDRESS;
            dappArgs = [
                { type: 'string', value: functionToCall },
                { type: 'integer', value: 0 },
                { type: 'integer', value: 0 },
                ...dappArgs,
                { type: 'string', value: '' },
                { type: 'boolean', value: false },
                { type: 'string', value: '' },
                { type: 'string', value: '' },
                { type: 'integer', value: 0 },
                { type: 'integer', value: 0 },
                { type: 'integer', value: 0 },
                { type: 'string', value: '' },
            ];
            functionToCall = 'callMarketplaceProxy';
        }

        const txOptions = {
            dApp: dappAddress,
            // fee: await this.getTotalFee(500000),
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: [],
        };

        return this.doSign(authType, txOptions);
    };

    placeBidCommon = async (authType, auctionId: auctionId, bidPrice: number, eggSell?: boolean) => {
        const txOptions = {
            dApp: AUCTION_DAPP_ADDRESS,
            call: {
                function: 'placeBid',
                args: [{ type: 'string', value: auctionId }],
            },
            payment: payment([
                {
                    assetId: eggSell ? EGG_ASSET_ID : null,
                    amount: bidPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    placeBidCF = async (
        authType,
        auctionId: auctionId,
        nftId: nftId,
        bidPrice: number,
        eggSell?: boolean,
        isTurtle?: boolean,
    ) => {
        const marketSignData = await this.getMarketSignData(nftId, isTurtle);

        const txOptions = {
            dApp: MARKETPLACE_PROXY_ADDRESS,
            call: {
                function: 'callMarketplaceProxy',
                args: [
                    { type: 'string', value: 'placeBid' },
                    { type: 'integer', value: 0 },
                    { type: 'integer', value: 0 },
                    { type: 'string', value: auctionId },
                    { type: 'string', value: '' },
                    { type: 'boolean', value: marketSignData.achievement },
                    { type: 'boolean', value: marketSignData.canbreed },
                    { type: 'string', value: marketSignData.genes },
                    { type: 'integer', value: marketSignData.price },
                    { type: 'integer', value: marketSignData.rarity },
                    { type: 'integer', value: marketSignData.timestamp },
                    { type: 'string', value: marketSignData.signature },
                ],
            },
            payment: payment([
                {
                    assetId: eggSell ? EGG_ASSET_ID : null,
                    amount: bidPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    placeBid = async (authType, auctionId: auctionId, nftId: nftId, bidPrice: number, eggSell?: boolean) => {
        const isCollectiveFarm = await this.getIsCollectiveFarm();

        return isCollectiveFarm
            ? this.placeBidCF(authType, auctionId, nftId, bidPrice, eggSell)
            : this.placeBidCommon(authType, auctionId, bidPrice, eggSell);
    };

    cancelBid = async (authType, auctionId, bidId) => {
        let dappArgs = [
            { type: 'string', value: auctionId },
            { type: 'string', value: bidId },
        ];
        let dappAddress = AUCTION_DAPP_ADDRESS;
        let functionToCall = 'cancelBid';

        if (await this.getIsCollectiveFarm()) {
            dappAddress = MARKETPLACE_PROXY_ADDRESS;
            dappArgs = [
                { type: 'string', value: functionToCall },
                { type: 'integer', value: 0 },
                { type: 'integer', value: 0 },
                ...dappArgs,
                { type: 'boolean', value: false },
                { type: 'boolean', value: false },
                { type: 'string', value: '' },
                { type: 'integer', value: 0 },
                { type: 'integer', value: 0 },
                { type: 'integer', value: 0 },
                { type: 'string', value: '' },
            ];
            functionToCall = 'callMarketplaceProxy';
        }

        const txOptions = {
            dApp: dappAddress,
            // fee: await this.getTotalFee(500000),
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: [],
        };

        return this.doSign(authType, txOptions);
    };

    acceptBid = async (authType, auctionId: auctionId, nftId: nftId, bidId: bidId, isTurtle?: boolean) => {
        let dappArgs = [
            { type: 'string', value: auctionId },
            { type: 'string', value: bidId },
        ];
        let dappAddress = AUCTION_DAPP_ADDRESS;
        let functionToCall = 'acceptBid';

        if (await this.getIsCollectiveFarm()) {
            const marketSignData = await this.getMarketSignData(nftId, isTurtle);

            dappAddress = MARKETPLACE_PROXY_ADDRESS;
            dappArgs = [
                { type: 'string', value: functionToCall },
                { type: 'integer', value: 0 },
                { type: 'integer', value: 0 },
                ...dappArgs,
                { type: 'boolean', value: marketSignData.achievement },
                { type: 'boolean', value: marketSignData.canbreed },
                { type: 'string', value: marketSignData.genes },
                { type: 'integer', value: marketSignData.price },
                { type: 'integer', value: marketSignData.rarity },
                { type: 'integer', value: marketSignData.timestamp },
                { type: 'string', value: marketSignData.signature },
            ];
            functionToCall = 'callMarketplaceProxy';
        }
        const txOptions = {
            dApp: dappAddress,
            // fee: await this.getTotalFee(500000),
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: [],
        };

        return this.doSign(authType, txOptions);
    };

    mergeItem = async (authType, itemName: string, assetsIds: string[], assetId: string, fee: number) => {
        const payments = assetsIds.map((assetId) => ({ assetId, amount: 1 }));

        const txOptions = {
            dApp: LOOT_BOXES_DAPP_ADDRESS,
            call: {
                function: 'instanMergeItem',
                args: [{ type: 'string', value: itemName }],
            },
            payment: payment([
                ...payments,
                {
                    assetId,
                    amount: fee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    directBuyItem = async (authType, itemName: ArtefactType, price: integer, priceAssetId: assetId, extraFee) => {
        const txOptions = {
            dApp: LOOT_BOXES_DAPP_ADDRESS,
            call: {
                function: 'directBuyItem',
                args: [{ type: 'string', value: itemName }],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: priceAssetId,
                    amount: price,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    directBuyItemMultiple = async (
        authType,
        itemName: ArtefactType,
        itemAmount,
        price: integer,
        priceAssetId: assetId,
        extraFee,
    ) => {
        const txOptions = {
            dApp: LOOT_BOXES_DAPP_ADDRESS,
            call: {
                function: 'directBuyItemMultiple',
                args: [
                    { type: 'string', value: itemName },
                    { type: 'integer', value: itemAmount },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: priceAssetId,
                    amount: price,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    startDuckBreeding = async (authType, duck1NftId, duck2NftId) => {
        const txOptions = {
            dApp: DUCK_BREEDER_DAPP_ADDRESS,
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'startDuckBreeding',
                args: [
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId: duck1NftId,
                    amount: 1,
                },
                {
                    assetId: duck2NftId,
                    amount: 1,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    breedTurtles = async (
        authType,
        firstTurtleId: turtleId,
        secondTurtleId: turtleId,
        breedPrice: integer,
        extraFee,
    ) => {
        const txOptions = {
            dApp: TURTLES_BREEDER_DAPP_ADDRESS,
            call: {
                function: 'startTRTLBreeding',
                args: [
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: firstTurtleId,
                    amount: 1,
                },
                {
                    assetId: secondTurtleId,
                    amount: 1,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: breedPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    breedDucks = async (
        authType,
        firstDuckId: duckId,
        secondDuckId: duckId,
        breedPrice: integer,
        spicePrice: integer,
        extraFee,
    ) => {
        const txOptions = {
            dApp: DUCK_BREEDER_DAPP_ADDRESS,
            call: {
                function: 'startDuckBreeding',
                args: [
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId: firstDuckId,
                    amount: 1,
                },
                {
                    assetId: secondDuckId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: SPICE_ASSET_ID,
                    amount: spicePrice,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: breedPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    breedCanines = async (authType, firstCanineId: duckId, secondCanineId: duckId, breedPrice: integer, extraFee) => {
        const txOptions = {
            dApp: CANINES_BREEDER_DAPP_ADDRESS,
            call: {
                function: 'startBreeding',
                args: [
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: firstCanineId,
                    amount: 1,
                },
                {
                    assetId: secondCanineId,
                    amount: 1,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: breedPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    breedFelines = async (authType, firstFelineId: duckId, secondFelineId: duckId, breedPrice: integer, extraFee) => {
        const txOptions = {
            dApp: FELINES_BREEDER_DAPP_ADDRESS,
            call: {
                function: 'startBreeding',
                args: [
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: firstFelineId,
                    amount: 1,
                },
                {
                    assetId: secondFelineId,
                    amount: 1,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: breedPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    finishDuckBreeding = async (authType, breedingTxId, extraFee) => {
        const txOptions = {
            dApp: DUCK_BREEDER_DAPP_ADDRESS,
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'finishDuckHatching',
                args: [
                    {
                        type: 'string',
                        value: breedingTxId,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    finishDucklingBreeding = async (authType, breedingTxId: txId, ducklingId: ducklingId, extraFee) => {
        const txOptions = {
            dApp: DUCK_BREEDER_DAPP_ADDRESS,
            call: {
                function: 'finishDuckHatching',
                args: [
                    {
                        type: 'string',
                        value: breedingTxId,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: ducklingId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    buyTurtleBeach = async (authType, color, perchPrice: eggint, extraFee) => {
        const txOptions = {
            dApp: TURTLES_FARMING_DAPP_ADDRESS,
            call: {
                function: 'buyBeach',
                args: [
                    {
                        type: 'string',
                        value: color,
                    },
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: perchPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    buyAnimalPerch = async (authType, color, perchPrice, extraFee, animal: AnimalType) => {
        const dappAnimalMap = this.dappAnimalFarming[animal];
        const txOptions = {
            dApp: dappAnimalMap.dapp,
            call: {
                function: 'buyPerch',
                args: [
                    {
                        type: 'string',
                        value: color,
                    },
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: perchPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    buyVPerch = async (authType, color, perchPrice: eggint, extraFee) => {
        const txOptions = {
            dApp: VEGG_FARMING_DAPP_ADDRESS,
            call: {
                function: 'buyPerch',
                args: [
                    {
                        type: 'string',
                        value: color,
                    },
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: perchPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    buyPerch = async (authType, color, perchPrice: eggint, extraFee) => {
        const txOptions = {
            dApp: DUCK_FARMING_DAPP_ADDRESS,
            call: {
                function: 'buyPerch',
                args: [
                    {
                        type: 'string',
                        value: color,
                    },
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: perchPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    buyMutuarium = async (authType, color, mutariumPrice: number, extraFee) => {
        const txOptions = {
            dApp: MUTANT_FARMING_DAPP,
            call: {
                function: 'buyMutuarium',
                args: [
                    {
                        type: 'string',
                        value: color,
                    },
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: mutariumPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    stakeNftVFarming = async (authType, nftId: string, color, extraFee) => {
        const txOptions = {
            dApp: VEGG_FARMING_DAPP_ADDRESS,
            call: {
                function: 'stakeNFT',
                args: [
                    {
                        type: 'string',
                        value: color,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: nftId,
                    amount: 1,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    stakeNftFarming = async (
        authType,
        nftId: string,
        color,
        perchOrigin: boolean,
        stakeWithoutPerch: boolean,
        extraFee,
    ) => {
        const txOptions = {
            dApp: DUCK_FARMING_DAPP_ADDRESS,
            call: {
                function: 'stakeNFT',
                args: [
                    {
                        type: 'string',
                        value: color,
                    },
                    {
                        type: 'boolean',
                        value: perchOrigin,
                    },
                    {
                        type: 'boolean',
                        value: stakeWithoutPerch,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: nftId,
                    amount: 1,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    unstakeNFT = async (authType, assetId, extraFee) => {
        let dappArgs = [{ type: 'string', value: assetId }];
        let dappAddress = DUCK_FARMING_DAPP_ADDRESS;
        let functionToCall = 'unstakeNFT';
        let payments = [
            {
                assetId: WAVES_ASSET_ID,
                amount: extraFee,
            },
        ];

        if (await this.getIsCollectiveFarm()) {
            dappAddress = this.getAccountAddress();
            dappArgs = [{ type: 'string', value: functionToCall }, ...dappArgs];
            functionToCall = 'callUnstakeProxy';
            payments = [];
        }

        const txOptions = {
            dApp: dappAddress,
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: payments,
        };

        return this.doSign(authType, txOptions);
    };

    turtleFarmingClaim = async (authType, extraFee) => {
        const txOptions = {
            dApp: TURTLES_FARMING_DAPP_ADDRESS,
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'claimReward',
                args: [],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    animalFarmingClaim = async (authType, assetId, extraFee, animal: AnimalType) => {
        const dappAnimalMap = this.dappAnimalFarming[animal];
        const txOptions = {
            dApp: dappAnimalMap.dapp,
            call: {
                function: 'claimReward',
                args: [
                    {
                        type: 'string',
                        value: assetId,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    farmingVeggClaim = async (authType, assetId, extraFee) => {
        const txOptions = {
            dApp: VEGG_FARMING_DAPP_ADDRESS,

            call: {
                function: 'claimReward',
                args: [
                    {
                        type: 'string',
                        value: assetId,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    farmingMutantClaim = async (authType, assetId, extraFee) => {
        const txOptions = {
            dApp: MUTANT_FARMING_DAPP,

            call: {
                function: 'claimReward',
                args: [
                    {
                        type: 'string',
                        value: assetId,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    farmingClaim = async (authType, assetId, extraFee) => {
        const txOptions = {
            dApp: DUCK_FARMING_DAPP_ADDRESS,

            call: {
                function: 'claimReward',
                args: [
                    {
                        type: 'string',
                        value: assetId,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    unstakeTurtleNFT = async (authType, assetId, extraFee) => {
        let dappArgs = [{ type: 'string', value: assetId }];
        let dappAddress = TURTLES_FARMING_DAPP_ADDRESS;
        let functionToCall = 'unstakeNFT';
        let payments = [
            {
                assetId: WAVES_ASSET_ID,
                amount: extraFee,
            },
        ];

        const txOptions = {
            dApp: dappAddress,
            // fee: await this.getTotalFee(500000),
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: payments,
        };

        return this.doSign(authType, txOptions);
    };

    unstakeMutantNFT = async (authType, assetId, extraFee) => {
        let dappArgs = [{ type: 'string', value: assetId }];
        let dappAddress = MUTANT_FARMING_DAPP;
        let functionToCall = 'unstakeNFT';
        let payments = [
            {
                assetId: WAVES_ASSET_ID,
                amount: extraFee,
            },
        ];

        const txOptions = {
            dApp: dappAddress,
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: payments,
        };

        return this.doSign(authType, txOptions);
    };

    unstakeAnimalNFT = async (authType, assetId, extraFee, animal: AnimalType) => {
        const dappAnimalMap = this.dappAnimalFarming[animal];
        let dappArgs = [{ type: 'string', value: assetId }];
        let dappAddress = dappAnimalMap.dapp;
        let functionToCall = 'unstakeNFT';
        let payments = [
            {
                assetId: WAVES_ASSET_ID,
                amount: extraFee,
            },
        ];

        const txOptions = {
            dApp: dappAddress,
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: payments,
        };

        return this.doSign(authType, txOptions);
    };

    stakeTurtleNFT = async (authType, assetId, extraFee) => {
        const txOptions = {
            dApp: TURTLES_FARMING_DAPP_ADDRESS,
            call: {
                function: 'stakeNFT',
                args: [],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId,
                    amount: 1,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    stakeMutantNFT = async (authType, assetId, extraFee) => {
        const txOptions = {
            dApp: MUTANT_FARMING_DAPP,
            call: {
                function: 'stakeNFT',
                args: [],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId,
                    amount: 1,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    stakeAnimalNFT = async (authType, assetId, extraFee, animal, dockingColorLetter) => {
        const dappAnimalMap = this.dappAnimalFarming[animal];

        if (!dappAnimalMap) {
            throw new Error(`Invalid animal type: ${animal}`);
        }

        const txOptions = {
            dApp: dappAnimalMap.dapp,
            call: {
                function: 'stakeNFT',
                args: [
                    {
                        type: 'string',
                        value: dockingColorLetter,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: assetId,
                    amount: 1,
                },
            ],
        };

        return await this.doSign(authType, txOptions);
    };

    stakeFarmTokens = async (authType: string, stakingContract: string, shareAssetId: string, amount: number) => {
        const txOptions = {
            dApp: stakingContract,
            call: {
                function: 'stakeFarmTokens',
                args: [
                    {
                        type: 'boolean',
                        value: false,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: shareAssetId,
                    amount,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    unlockFarmTokens = async (authType: string, stakingContract: string, amount: number) => {
        const txOptions = {
            dApp: stakingContract,
            call: {
                function: 'withdrawFarmTokens',
                args: [
                    {
                        type: 'integer',
                        value: amount,
                    },
                    {
                        type: 'boolean',
                        value: false,
                    },
                ],
            },
            payment: [],
        };

        return this.doSign(authType, txOptions);
    };

    claimReward = async (authType: string, stakingContract: string) => {
        const txOptions = {
            dApp: stakingContract,
            call: {
                function: 'claimReward',
                args: [],
            },
            payment: [],
        };

        return this.doSign(authType, txOptions);
    };

    startShakeIt = async (authType, assetId, feeAmount) => {
        const txOptions = {
            dApp: LOOT_BOXES_DAPP_ADDRESS,
            call: {
                function: 'startShakeItBaby',
                args: [],
            },
            payment: payment([
                {
                    assetId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: feeAmount,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    finishShakeIt = async (authType, initTxId, feeAmount) => {
        const txOptions = {
            dApp: LOOT_BOXES_DAPP_ADDRESS,
            call: {
                function: 'finishShakeItBaby',
                args: [
                    {
                        type: 'string',
                        value: initTxId,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: feeAmount,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    initRebirth = async (
        authType,
        assetId,
        rebirthPrice: integer | undefined,
        secondRebirthPrice: integer | undefined,
        extraFee: integer,
        animalType: AnimalType,
    ) => {
        const payments = [
            {
                assetId,
                amount: 1,
            },
        ];

        if (extraFee && animalType !== AnimalType.CANINES && animalType !== AnimalType.FELINES) {
            payments.push({
                assetId: WAVES_ASSET_ID,
                amount: extraFee,
            });
        }
        if (rebirthPrice) {
            payments.push({
                assetId: this.dappAnimalRebirth[animalType].asset,
                amount: rebirthPrice,
            });
        }
        if (secondRebirthPrice && this.dappAnimalRebirth[animalType].secondAsset) {
            payments.push({
                assetId: this.dappAnimalRebirth[animalType].secondAsset,
                amount: secondRebirthPrice,
            });
        }
        if (extraFee && animalType === AnimalType.FELINES) {
            payments.push({
                assetId: WAVES_ASSET_ID,
                amount: extraFee,
            });
        }

        const txOptions = {
            dApp: this.dappAnimalRebirth[animalType].dapp,
            call: {
                function: 'initRebirth',
                args:
                    animalType === AnimalType.DUCKS
                        ? [
                              {
                                  type: 'string',
                                  value: await getReferrerAddress(),
                              },
                          ]
                        : [],
            },
            payment: payment(payments),
        };

        return this.doSign(authType, txOptions);
    };

    finishRebirth = async (authType, initTxId, feeAmount, animalType: AnimalType) => {
        const txOptions = {
            dApp: this.dappAnimalRebirth[animalType].dapp,
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'finishRebirth',
                args: [
                    {
                        type: 'string',
                        value: initTxId,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: feeAmount,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    finishRebirthDouble = async (authType, initTxId, artefactId, feeAmount, animalType: AnimalType) => {
        const txOptions = {
            dApp: this.dappAnimalRebirth[animalType].dapp,
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'finishRebirthDouble',
                args: [
                    {
                        type: 'string',
                        value: initTxId,
                    },
                ],
            },
            payment: [
                {
                    assetId: artefactId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: feeAmount,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    initTurtleRebirth = async (authType, assetId, wavesAmount, spiceAmount, rebirthPrice) => {
        const txOptions = {
            dApp: TURTLE_REBIRTH_DAPP_ADDRESS,
            call: {
                function: 'initRebirth',
                args: [
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: wavesAmount,
                },
                {
                    assetId: SPICE_ASSET_ID,
                    amount: spiceAmount,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: rebirthPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    finishTurtleRebirthDouble = async (authType, initTxId, artefactId, feeAmount) => {
        const txOptions = {
            dApp: TURTLE_REBIRTH_DAPP_ADDRESS,
            call: {
                function: 'finishRebirthDouble',
                args: [
                    {
                        type: 'string',
                        value: initTxId,
                    },
                ],
            },
            payment: [
                {
                    assetId: artefactId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: feeAmount,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    finishTurtleRebirth = async (authType, initTxId, feeAmount) => {
        const txOptions = {
            dApp: TURTLE_REBIRTH_DAPP_ADDRESS,
            call: {
                function: 'finishRebirth',
                args: [
                    {
                        type: 'string',
                        value: initTxId,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: feeAmount,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    makeBidForSpencer = async (authType, auctionId, assetId, amount, buyout) => {
        const txOptions = {
            dApp: SPENCER_PROXY_DAPP_ADDRESS,
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'buyWaves',
                args: [
                    {
                        type: 'string',
                        value: auctionId,
                    },
                    {
                        type: 'boolean',
                        value: buyout,
                    },
                ],
            },
            payment: payment([
                {
                    assetId,
                    amount,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    swapEgg = async (authType, eggAmount) => {
        const txOptions = {
            dApp: SWAP_DAPP_ADDRESS,
            // fee: await this.getTotalFee(500000),
            call: {
                function: 'swapEgg',
                args: [],
            },
            payment: payment([
                {
                    amount: eggAmount,
                    assetId: OLD_EGG_ASSET_ID,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    takeOffArtefact = async (authType, duckId, extraFee, artefactType = 'mantle') => {
        const txOptions = {
            dApp: GAME_DAPP_ADDRESS,
            call: {
                function: 'takeOffArtefact',
                args: [
                    {
                        type: 'string',
                        value: duckId,
                    },
                    {
                        type: 'string',
                        value: artefactType,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    unlockFromDuckHunt = async (authType: string, duckId: string, signature: string, timestamp: integer, extraFee) => {
        const txOptions = {
            dApp: HUNT_ADDRESS,
            call: {
                function: 'unlockDuck',
                args: [
                    {
                        type: 'string',
                        value: duckId,
                    },
                    {
                        type: 'integer',
                        value: timestamp,
                    },
                    {
                        type: 'boolean',
                        value: true,
                    },
                    {
                        type: 'string',
                        value: signature,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    unlockFromGame = async (authType: string, duckId: string, gameName: Worlds) => {
        let DAPP_ADDRESS = '';

        if (gameName === Worlds.Hunt) {
            DAPP_ADDRESS = HUNT_ADDRESS;
        }

        const txOptions = {
            dApp: DAPP_ADDRESS,
            call: {
                function: 'unlockDuck',
                args: [
                    {
                        type: 'string',
                        value: duckId,
                    },
                ],
            },
            payment: [],
        };

        return this.doSign(authType, txOptions);
    };

    upgradeArtefact = async (authType, artefactId, paymentSize, couponsAmount) => {
        const txOptions = {
            dApp: GAME_DAPP_ADDRESS,
            call: {
                function: 'upgradeMantleByCoupons',
                args: [
                    {
                        type: 'string',
                        value: artefactId,
                    },
                    {
                        type: 'integer',
                        value: couponsAmount,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: EGG_ASSET_ID,
                    amount: paymentSize,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    /**
     *
     * @param authType
     * @param data
     * @returns {Promise<any>}
     */
    signGameData = async (authType, data) => {
        await this.init(authType);
        if (authType === KEEPER_LOGIN) {
            return window.WavesKeeper.signCustomData({
                version: 2,
                data,
            }).then(({ signature }) => signature);
        }

        return this.signer[authType].signMessage(JSON.stringify(data));
    };

    /**
     *
     * @param authType {String}
     * @param address {String}
     * @returns {Promise<{ data: Array<any>, signature: string,timestamp: number }>}
     */
    signAuthData = async (
        authType,
        address: addressId,
    ): Promise<{ data: CustomData[]; signature: string; timestamp: integer }> => {
        await this.init(authType);
        const timestamp = Date.now() as integer;
        const data = [
            { type: 'string' as const, key: 'address', value: address },
            { type: 'integer' as const, key: 'ts', value: timestamp },
        ];
        if (authType === KEEPER_LOGIN) {
            return window.WavesKeeper.signCustomData({ version: 2, data }).then(({ data, signature, publicKey }) => ({
                data,
                signature,
                timestamp,
                publicKey,
            }));
        }
        return this.signer[authType].signMessage(JSON.stringify(data)).then((signature) => ({
            data,
            signature,
            timestamp,
        }));
    };

    investEggInCollectiveFarm = async (authType, farmAddress, amount) => {
        const txOptions = {
            dApp: farmAddress,
            call: {
                function: 'provideLiquidity',
                args: [],
            },
            payment: payment([
                {
                    assetId: EGG_ASSET_ID,
                    amount,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    buyEggs = async (authType: string, amount: number, assetId: assetId | null): Promise<void> => {
        const txOptions = {
            dApp: SWOP_DAPP_ADDRESS,
            call: { function: 'buyEgg', args: [] },
            payment: [{ assetId, amount }],
        };

        return this.doSign(authType, txOptions);
    };

    buyMantle = async (authType, price) => {
        const txOptions = {
            dApp: GAME_DAPP_ADDRESS,
            call: {
                function: 'buyArtefact',
                args: [
                    {
                        type: 'string',
                        value: 'mantle',
                    },
                ],
            },
            payment: payment([
                {
                    assetId: EGG_ASSET_ID,
                    amount: price,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    buyLootBox = async (authType) => {
        const txOptions = {
            dApp: LOOT_BOXES_DAPP_ADDRESS,
            call: {
                function: 'buyArtefact',
                args: [],
            },
            payment: payment([
                {
                    assetId: EGG_ASSET_ID,
                    amount: 3 * 1e8,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    openLootBox = async (boxId, authType) => {
        const txOptions = {
            dApp: LOOT_BOXES_DAPP_ADDRESS,
            call: {
                function: 'claimArtefact',
                args: [{ type: 'string', value: boxId }],
            },
            payment: [],
        };

        return this.doSign(authType, txOptions);
    };

    feedGenesisDuckling = async (
        authType,
        data: {
            ducklingId: ducklingId;
            signature: string;
            maxToFeed: integer;
            userNonce: integer;
            feedAmount: integer;
        },
        extraFee,
    ) => {
        const { ducklingId, signature, maxToFeed, userNonce, feedAmount } = data;

        const txOptions = {
            dApp: BABY_DUCKS_DAPP_ADDRESS,
            call: {
                function: 'feedDuckling',
                args: [
                    {
                        type: 'string',
                        value: ducklingId,
                    },
                    {
                        type: 'string',
                        value: signature,
                    },
                    {
                        type: 'integer',
                        value: maxToFeed,
                    },
                    {
                        type: 'integer',
                        value: userNonce,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: SPICE_ASSET_ID,
                    amount: feedAmount,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    feedBredDuckling = async (authType, ducklingId: ducklingId, feedAmount: integer, extraFee) => {
        const txOptions = {
            dApp: BREEDING_BABY_DUCKS_DAPP_ADDRESS,
            call: {
                function: 'feedDuckling',
                args: [
                    {
                        type: 'string',
                        value: ducklingId,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: SPICE_ASSET_ID,
                    amount: feedAmount,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    buyGenesisTurtle = async (authType, turtlePrice, extraFee) => {
        const txOptions = {
            dApp: TURTLES_INCUBATOR_DAPP_ADDRESS,
            call: {
                function: 'startTRTLHatching',
                args: [
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: turtlePrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    buyGenesisAnimal = async (authType, animalPrice, extraFee, animal: ANIMAL_PREFIXES) => {
        const dappAnimalMap = this.dappAnimalMap[animal];
        const txOptions = {
            dApp: dappAnimalMap.dapp,
            call: {
                function: 'startHatching',
                args: [
                    {
                        type: 'string',
                        value: '',
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: dappAnimalMap.asset,
                    amount: animalPrice > 0 ? animalPrice : 100,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    finishBreedAnimalsHatching = async (authType, breedingTxId, extraFee, animal: ANIMAL_PREFIXES, isBreed = false) => {
        const dappAnimalMap = isBreed ? this.dappAnimalBreeder[animal] : this.dappAnimalMap[animal];

        const txOptions = {
            dApp: dappAnimalMap.dapp,
            call: {
                function: 'finishHatching',
                args: [
                    {
                        type: 'string',
                        value: breedingTxId,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    buyDuckling = async (authType, ducklingPrice: integer, extraFee) => {
        const txOptions = {
            dApp: BABY_DUCKS_DAPP_ADDRESS,
            call: {
                function: 'buyDuckling',
                args: [
                    {
                        type: 'string',
                        value: await getReferrerAddress(),
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: ducklingPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    putOnArtefact = async (authType, duckId, artefactId, extraFee) => {
        const txOptions = {
            dApp: GAME_DAPP_ADDRESS,
            call: {
                function: 'putOnArtefact',
                args: [
                    {
                        type: 'string',
                        value: artefactId,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: duckId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    applyFixGeneArtefact = async (
        authType,
        breedingTxId: txId,
        artefactId: assetId,
        fromParent: integer,
        fixedGenePosition: integer,
        extraFee,
        animalType: ANIMAL_PREFIXES,
        ducklingId?: assetId,
    ) => {
        const duckPayments = [
            {
                assetId: artefactId,
                amount: 1,
            },
            {
                assetId: ducklingId as string,
                amount: 1,
            },
            {
                assetId: WAVES_ASSET_ID,
                amount: extraFee,
            },
        ];
        const animalPayments = [
            {
                assetId: artefactId,
                amount: 1,
            },
            {
                assetId: WAVES_ASSET_ID,
                amount: extraFee,
            },
        ];

        const payments = animalType === ANIMAL_PREFIXES.DUCK ? duckPayments : animalPayments;

        const txOptions = {
            dApp: this.dappAnimalBreeder[animalType].dapp,
            call: {
                function: 'fixedGene',
                args: [
                    {
                        type: 'string',
                        value: breedingTxId,
                    },
                    {
                        type: 'integer',
                        value: fromParent,
                    },
                    {
                        type: 'integer',
                        value: fixedGenePosition,
                    },
                ],
            },
            payment: payment(payments),
        };

        return this.doSign(authType, txOptions);
    };

    applyFreeGeneArtefact = async (
        authType,
        breedingTxId: txId,
        artefactId: assetId,
        extraFee,
        animalType: ANIMAL_PREFIXES,
        ducklingId?: assetId,
    ) => {
        const duckPayments = [
            {
                assetId: artefactId,
                amount: 1,
            },
            {
                assetId: ducklingId as string,
                amount: 1,
            },
            {
                assetId: WAVES_ASSET_ID,
                amount: extraFee,
            },
        ];
        const animalPayments = [
            {
                assetId: artefactId,
                amount: 1,
            },
            {
                assetId: WAVES_ASSET_ID,
                amount: extraFee,
            },
        ];

        const payments = animalType === ANIMAL_PREFIXES.DUCK ? duckPayments : animalPayments;

        const txOptions = {
            dApp: this.dappAnimalBreeder[animalType].dapp,
            call: {
                function: 'freeGene',
                args: [
                    {
                        type: 'string',
                        value: breedingTxId,
                    },
                ],
            },
            payment: payment(payments),
        };

        return this.doSign(authType, txOptions);
    };

    turnIntoDuck = async (authType, ducklingId, extraFee) => {
        const txOptions = {
            dApp: BABY_DUCKS_DAPP_ADDRESS,
            call: {
                function: 'turnDucklingIntoDuck',
                args: [],
            },
            payment: payment([
                {
                    assetId: ducklingId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    oneTimeUseFeed = async (authType, artefactId: assetId, extraFee) => {
        const txOptions = {
            dApp: ACCOUNT_BOOSTER_DAPP_ADDRESS,
            call: {
                function: 'oneTimeUseFeed',
                args: [],
            },
            payment: payment([
                {
                    assetId: artefactId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    redeemMutuarium = async (authType, artefactId: assetId, extraFee) => {
        const txOptions = {
            dApp: MUTANT_FARMING_DAPP,
            call: {
                function: 'redeemMutuarium',
                args: [],
            },
            payment: payment([
                {
                    assetId: artefactId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    stakeItem = async (authType, artefactId: assetId, extraFee) => {
        const txOptions = {
            dApp: ACCOUNT_BOOSTER_DAPP_ADDRESS,
            call: {
                function: 'stakeItem',
                args: [],
            },
            payment: payment([
                {
                    assetId: artefactId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    unstakeItem = async (authType, artefactType: ArtefactType, extraFee) => {
        const txOptions = {
            dApp: ACCOUNT_BOOSTER_DAPP_ADDRESS,
            call: {
                function: 'unstakeItem',
                args: [
                    {
                        type: 'string',
                        value: artefactType,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    usePotion = async (authType, animalId: duckId, type, artefactId, potionFee) => {
        //typing as duckId because for now only works for ducks.
        const txOptions = {
            dApp: DUCK_FARMING_DAPP_ADDRESS,
            call: {
                function: 'redeemItem',
                args: [
                    {
                        type: 'string',
                        value: type,
                    },
                    {
                        type: 'string',
                        value: animalId,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: potionFee,
                },
                {
                    assetId: artefactId,
                    amount: 1,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    stakeDuckHouse = async (authType, type: ArtefactType, artefactId: assetId, extraFee) => {
        const txOptions = {
            dApp: typeHouse(type),
            call: {
                function: 'stakeDuckHouse',
                args: [],
            },
            payment: payment([
                {
                    assetId: artefactId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    unstakeDuckHouse = async (authType, type: ArtefactType, artefactId: assetId, extraFee) => {
        const txOptions = {
            dApp: typeHouse(type),
            call: {
                function: 'unstakeDuckHouse',
                args: [
                    {
                        type: 'string',
                        value: artefactId,
                    },
                ],
            },
            payment: [
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ],
        };

        return this.doSign(authType, txOptions);
    };

    stakeDuckIntoHouse = async (
        authType,
        type: ArtefactType,
        duckHouseId: assetId,
        duckId: duckId,
        color: string,
        extraFee,
    ) => {
        const txOptions = {
            dApp: typeHouse(type),
            call: {
                function: 'stakeDuck',
                args: [
                    {
                        type: 'string',
                        value: duckHouseId,
                    },
                    {
                        type: 'string',
                        value: color,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: duckId,
                    amount: 1,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    unstakeDuckFromHouse = async (authType, type: ArtefactType, duckId: duckId, extraFee) => {
        let payments = [
            {
                assetId: WAVES_ASSET_ID,
                amount: extraFee,
            },
        ];
        const txOptions = {
            dApp: typeHouse(type),
            call: {
                function: 'unstakeDuck',
                args: [
                    {
                        type: 'string',
                        value: duckId,
                    },
                ],
            },
            payment: payments,
        };

        return this.doSign(authType, txOptions);
    };

    claimRewardDuckIntoHouseAddresss = async (authType, address: String, duckId: duckId, extraFee) => {
        const txOptions = {
            dApp: address,
            call: {
                function: 'claimDuckRewards',
                args: [
                    {
                        type: 'string',
                        value: duckId,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    claimRewardDuckIntoHouse = async (authType, type: ArtefactType, duckId: duckId, extraFee) => {
        const txOptions = {
            dApp: typeHouse(type),
            call: {
                function: 'claimDuckRewards',
                args: [
                    {
                        type: 'string',
                        value: duckId,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };
    copyDuck = async (authType, duckId: duckId, artefactId: assetId, extraFee) => {
        const txOptions = {
            dApp: LOOT_BOXES_DAPP_ADDRESS,
            call: {
                function: 'copyDuck',
                args: [],
            },
            payment: payment([
                {
                    assetId: duckId,
                    amount: 1,
                },
                {
                    assetId: artefactId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    copyArtefact = async (authType, dupliArtefact: assetId, artefactId: assetId, eggPayment, extraFee) => {
        const txOptions = {
            dApp: LOOT_BOXES_DAPP_ADDRESS,
            call: {
                function: 'itemDuplicator',
                args: [],
            },
            payment: payment([
                {
                    assetId: dupliArtefact,
                    amount: 1,
                },
                {
                    assetId: artefactId,
                    amount: 1,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: eggPayment,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    addArtefactToDuck = async (authType, duckId: duckId, artefactId: assetId, extraFee) => {
        const txOptions = {
            dApp: LOOT_BOXES_DAPP_ADDRESS,
            call: {
                function: 'addArteFactToDuck',
                args: [],
            },
            payment: payment([
                {
                    assetId: duckId,
                    amount: 1,
                },
                {
                    assetId: artefactId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    wearItemToDuck = async (authType, duckId: duckId, artefactId: assetId, extraFee) => {
        const txOptions = {
            dApp: WEARABLES_DAPP_ADDRESS,
            call: {
                function: 'wearItemToDuck',
                args: [],
            },
            payment: payment([
                {
                    assetId: duckId,
                    amount: 1,
                },
                {
                    assetId: artefactId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    removeItemFromDuck = async (
        authType,
        duckId: duckId,
        roboDuckId: assetId,
        extraFee,
        burnItem: boolean | undefined,
        artefactName: assetId,
    ) => {
        const txOptions = {
            dApp: WEARABLES_DAPP_ADDRESS,
            call: {
                function: 'removeItemFromDuck',
                args: [
                    {
                        type: 'boolean',
                        value: burnItem,
                    },
                    {
                        type: 'string',
                        value: artefactName,
                    },
                ],
            },
            payment: payment([
                {
                    assetId: duckId,
                    amount: 1,
                },
                {
                    assetId: roboDuckId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    wearManyItemsToDuck = async (authType, duckId: duckId, artefacts: string[], extraFee) => {
        const txOptions = {
            dApp: WEARABLES_DAPP_ADDRESS,
            call: {
                function: 'wearXItemsToDuck',
                args: [],
            },
            payment: payment([
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
                {
                    assetId: duckId,
                    amount: 1,
                },
                ...artefacts.map((artefactId) => ({
                    assetId: artefactId,
                    amount: 1,
                })),
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    buyArGlasses = async (authType, extraFee) => {
        const txOptions = {
            dApp: HUNT_ADDRESS,
            call: {
                function: 'buyAccessItem',
                args: [],
            },
            payment: payment([
                {
                    assetId: EGG_ASSET_ID,
                    amount: 1e7,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    lockDuckForHunt = async (authType, duckId: duckId, extraFee) => {
        const txOptions = {
            dApp: HUNT_ADDRESS,
            call: {
                function: 'lockDuck',
                args: [],
            },
            payment: payment([
                {
                    assetId: duckId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    claimDuckBack = async (authType, duckId: duckId, debt) => {
        const txOptions = {
            dApp: RENT_ADDRESS,
            call: {
                function: 'claimDuckBack',
                args: [{ type: 'string', value: duckId }],
            },
            payment: payment([
                {
                    assetId: EGG_ASSET_ID,
                    amount: debt,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    sendDuckBack = async (authType, duckId: duckId) => {
        const txOptions = {
            dApp: RENT_ADDRESS,
            call: {
                function: 'sendDuckBack',
                args: [{ type: 'string', value: duckId }],
            },
            payment: payment([]),
        };

        return this.doSign(authType, txOptions);
    };

    rentDuckFromUser = async (authType, duckId: duckId, fee: number) => {
        const txOptions = {
            dApp: RENT_ADDRESS,
            call: {
                function: 'rentDuckFromUser',
                args: [
                    { type: 'string', value: duckId },
                    { type: 'string', value: 'HUNT' },
                ],
            },
            payment: payment([
                {
                    assetId: EGG_ASSET_ID,
                    amount: fee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    lockDuckForRent = async (authType, duckId: duckId, durationInMs: number, percentage: number) => {
        const txOptions = {
            dApp: RENT_ADDRESS,
            call: {
                function: 'putForRent',
                args: [
                    { type: 'integer', value: durationInMs },
                    { type: 'integer', value: percentage },
                ],
            },
            payment: payment([
                {
                    assetId: duckId,
                    amount: 1,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    buyGlassesAndLockDuckForRent = async (
        authType,
        duckId: duckId,
        durationInMs: number,
        percentage: number,
        glassesPrice: integer,
    ) => {
        const txOptions = {
            dApp: RENT_ADDRESS,
            call: {
                function: 'buyGlassesAndPutForRent',
                args: [
                    { type: 'integer', value: durationInMs },
                    { type: 'integer', value: percentage },
                ],
            },
            payment: payment([
                {
                    assetId: duckId,
                    amount: 1,
                },
                {
                    assetId: EGG_ASSET_ID,
                    amount: glassesPrice,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    buyGlassesAndLockDuckForHunt = async (authType, duckId: duckId, glassesPrice: integer, extraFee) => {
        const txOptions = {
            dApp: HUNT_ADDRESS,
            call: {
                function: 'buyAccessItemAndLockDuck',
                args: [],
            },
            payment: payment([
                {
                    assetId: EGG_ASSET_ID,
                    amount: glassesPrice,
                },
                {
                    assetId: duckId,
                    amount: 1,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    levelUpForHunt = async (authType: string, levelUpPrice: number, extraFee) => {
        const txOptions = {
            dApp: SKILL_TREE_DAPP,
            call: {
                function: 'levelUp',
                args: [],
            },
            payment: payment([
                {
                    assetId: EGG_ASSET_ID,
                    amount: levelUpPrice,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    resetSkillsForHunt = async (authType: string, resetSkillsPrice: number, extraFee) => {
        const txOptions = {
            dApp: SKILL_TREE_DAPP,
            call: {
                function: 'reset',
                args: [],
            },
            payment: payment([
                {
                    assetId: EGG_ASSET_ID,
                    amount: resetSkillsPrice,
                },
                {
                    assetId: WAVES_ASSET_ID,
                    amount: extraFee,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    swapAsset = async (authType, assetOut: string, minimum: number, amount: number, assetIn: string, amm: string) => {
        let dappArgs = [
            { type: 'string', value: assetOut },
            { type: 'integer', value: minimum },
        ];
        let dappAddress = amm;
        let functionToCall = 'swap';

        const txOptions = {
            dApp: dappAddress,
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: payment([
                {
                    assetId: assetIn,
                    amount,
                },
            ]),
        };

        return this.doSign(authType, txOptions);
    };

    AMMDeposit = async (authType, payments: Array<{ assetId: string; amount: number }>, amm) => {
        let dappArgs = [{ type: 'string', value: amm }];
        let dappAddress = AMM_DAPP_LAYER_2_ADDRESS;
        let functionToCall = 'generateAndStakeIndex';

        const txOptions = {
            dApp: dappAddress,
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: payment(payments),
        };

        return this.doSign(authType, txOptions);
    };

    AMMWithdraw = async (authType, amount, amm) => {
        let dappArgs = [
            { type: 'string', value: amm },
            { type: 'integer', value: amount },
        ];
        let dappAddress = AMM_DAPP_LAYER_2_ADDRESS;
        let functionToCall = 'unstakeAndRedeemIndex';

        const txOptions = {
            dApp: dappAddress,
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: [],
        };

        return this.doSign(authType, txOptions);
    };

    AMMClaim = async (authType, amm) => {
        let dappArgs = [];
        let dappAddress = amm;
        let functionToCall = 'claimIndexRewards';

        const txOptions = {
            dApp: dappAddress,
            call: {
                function: functionToCall,
                args: dappArgs,
            },
            payment: [],
        };

        return this.doSign(authType, txOptions);
    };

    leaseWaves = async (authType, amount) => {
        await this.init(authType);
        const txOptions = {
            recipient: LEASE_DAPP_ADDRESS,
            amount: amount * 1e8,
        };

        const authedTxOptions = {
            type: 8,
            data: {
                amount: {
                    tokens: amount,
                },
                recipient: txOptions.recipient,
                fee: {
                    tokens: await this.getTotalFee('0.001'),
                    assetId: 'WAVES',
                },
            },
        };
        if (authType === KEEPER_LOGIN) {
            return window.WavesKeeper.signAndPublishTransaction(authedTxOptions);
        }

        return this.signer[authType].lease(txOptions).broadcast();
    };

    cancelLease = async (authType, leaseId) => {
        await this.init(authType);
        const txOptions = {
            leaseId,
        };

        if (authType === KEEPER_LOGIN) {
            return window.WavesKeeper.signAndPublishTransaction({
                type: 9,
                data: {
                    leaseId: txOptions.leaseId,
                    fee: {
                        tokens: await this.getTotalFee('0.001'),
                        assetId: 'WAVES',
                    },
                },
            });
        }
        return this.signer[authType].cancelLease(txOptions).broadcast();
    };

    private getMarketSignData = async (assetId: string, isTurtle?: boolean, isMutant?: boolean) => {
        let assetData;

        if (isTurtle) {
            assetData = (await axios.get(`${API_URL}/v1/turtles/nft/${assetId}`)).data.turtleDetails;
        }
        if (isMutant) {
            assetData = (await axios.get(`${API_URL}/v2/mutants/nft/${assetId}`)).data.assetDetails;
        } else {
            assetData = (await axios.get(`${API_URL}/v1/ducks/nft/${assetId}`)).data;
        }

        let signData;
        if (this.isDuck(assetData.name)) {
            const achievements = assetData.achievements.a.length > 0;
            const genes = assetData.name.split('-')[1];
            const marketData = (
                await axios.get(
                    `${SCAN_URL}/market/signature?rarities=${assetData.rarity}&canbreed=${assetData.canBreed}&achievement=${achievements}&genes=${genes}`,
                )
            ).data;
            signData = marketData.signData;
            signData.signature = marketData.signature;
        } else if (this.isTurtle(assetData.name)) {
            const marketData = (
                await axios.get(
                    `${SCAN_URL}/market/turtle/signature?rarities=${assetData.basePower}&canbreed=${assetData.canBreed}`,
                )
            ).data;
            signData = { ...marketData.signData, achievement: false, genes: '' };
            signData.signature = marketData.signature;
        } else if (this.isMutant(assetData.assetDetails.description)) {
            const marketData = (
                await axios.get(
                    `${SCAN_URL}/market/mutant/signature?rarities=${mutantsFarmingService.addMutantsFarmPower(
                        assetData.assetDetails.description,
                    )}&canbreed=${assetData.canBreed}`,
                )
            ).data;
            signData = { ...marketData.signData, achievement: false, genes: '' };
            signData.signature = marketData.signature;
        } else {
            const marketData = (await axios.get(`${SCAN_URL}/market/items/signature?item=${assetData.name}`)).data;
            signData = {
                achievement: false,
                canbreed: false,
                genes: marketData.signData.item,
                price: marketData.signData.price,
                rarity: 0,
                timestamp: marketData.signData.timestamp,
                signature: marketData.signature,
            };
        }

        return signData;
    };

    private isDuck(name: string) {
        return /(DUCK-)([A-z]{8})-([A-z]{2})/.test(name);
    }

    private isTurtle(name: string) {
        return /(TRTL-)([A-z]{8})-([A-z]{2})/.test(name);
    }

    private isMutant(name: string) {
        return /(MTNT-)([A-z]{16})-([A-z]{2})/.test(name);
    }
}

export default AuthenticationService;
