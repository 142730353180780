/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAddress } from '$shared/domain/constants';
import { isAddress } from '$shared/domain/guards';
import CommonTurtlesService from '$shared/services/turtles';
import { ITurtlesDetails } from '$shared/types';
import { AnimalConnection, IAnimalDetailsV2 } from '$shared/types/animals';
import { ITurtleDetailsV2, ITurtleDetailsLocked } from '$shared/types/turtles';
import { urlString } from '$shared/utils';

import { API_URL, APP_URL, IMAGES_SERVICE_URL } from '$/constants';

import assetsService from '../assets';
import auctionService from '../auctions';
import helperService from '../helper';
import productionService from '../production';
import scanService from '../scan';
import turtlesBreedingService from '../turtles-breeding';
import turtleFarmingService from '../turtles-farming';
import turtlesIncubatorService from '../turtles-incubator';

class FrontendTurtleService extends CommonTurtlesService {
    breedingService = turtlesBreedingService;

    farmingService = turtleFarmingService;

    incubatorService = turtlesIncubatorService;

    scanService = scanService;

    helperService = helperService;

    assetsService = assetsService;

    turtlesIncubatorService = turtlesIncubatorService;

    productionService = productionService;

    IMAGES_SERVICE_URL = IMAGES_SERVICE_URL;

    auctionService = auctionService;

    turtleBreedingService = turtlesBreedingService;

    turtleFarmingService = turtleFarmingService;

    APP_URL = APP_URL;

    API_URL = API_URL;

    DAPPS_CONTRACTS = {
        farming: getAddress('TURTLES_FARMING_DAPP'),
        auction: getAddress('AUCTION_DAPP'),
        rental: getAddress('RENT_DAPP_ADDRESS'),
    };

    fetchOneTurtleDetails = async (nftId: string): Promise<ITurtlesDetails> => {
        const [turtle] = await Promise.all([this.getOneAnimalDetails(nftId)]);
        return turtle;
    };

    fetchOneTurtleDetailsV2 = async (nftId: string): Promise<ITurtleDetailsLocked> => {
        const [turtle] = await Promise.all([this.getOneTurtleDetailsV2(nftId)]);
        return turtle;
    };

    fetchUserPageTurtles = async (address: addressId): Promise<ITurtlesDetails[]> => {
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get<ITurtlesDetails[]>(
            urlString(`${API_URL}/v1/address/${address}/turtles`, {
                withStakedOn: ['none', 'farming', 'auction'],
                with: ['breedStatus', 'farmingStats', 'achievements'],
            }),
        );

        return data;
    };

    fetchOneAnimalDetailsV2 = async (nftId: string): Promise<ITurtleDetailsV2> => {
        const [turtles] = await Promise.all([this.getOneAnimalDetailsV2(nftId, true)]);
        return turtles;
    };

    fetchUserPageTurtleV2 = async (address: addressId): Promise<ITurtleDetailsLocked[]> => {
        if (!isAddress(address)) {
            return [];
        }
        try {
            const { data } = await this.helperService.http.get(
                urlString(`${API_URL}/v2/addresses/${address}/turtles/locked`),
                {
                    params: { size: 200_000 },
                },
            );

            const { data: result } = data;

            const allTurtlesFromUser: ITurtleDetailsLocked[] = result.data.map((turtle: IAnimalDetailsV2) => ({
                ...turtle,
                onFarming: turtle.locks.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.farming,
                ),
                onSale: turtle.locks.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.auction,
                ),
            }));

            return allTurtlesFromUser;
        } catch (error) {
            console.error('[ERROR][FETCH USER PAGE TURTLES]', error);
            return [];
        }
    };

    fetchFreeTurtlesBreeding = async (address: addressId, forMutants = false): Promise<ITurtleDetailsV2[]> => {
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get<any>(
            urlString(`${API_URL}/v2/addresses/${address}/turtles`),
            {
                params: {
                    size: 200_000,
                    details: false,
                },
            },
        );
        const { data: result } = data;
        return result.data.filter(
            (turtle: ITurtleDetailsV2) =>
                turtle.canBreed &&
                (forMutants ? this.isLastGeneration(turtle.name) : !this.isLastGeneration(turtle.name)),
        );
    };

    fetchAnimalConnections = async (assetId: turtleId): Promise<AnimalConnection[]> => {
        const { data: { data = [] } = {} } = await this.helperService.http.get<{ data: AnimalConnection[] }>(
            `${API_URL}/v2/turtles/${assetId}/connections`,
        );

        return data;
    };
}

export default FrontendTurtleService;
