/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames';
import React, { ReactNode, SyntheticEvent, forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Swal from '$/components/Swal';

import st from './Button.module.scss';

export type ButtonColor =
    | 'default'
    | 'secondary'
    | 'yellow'
    | 'yellowReverse'
    | 'pink'
    | 'cyan'
    | 'violet'
    | 'black'
    | 'darkGray'
    | 'green'
    | 'white'
    | 'rose'
    | 'genotype';

export interface Props {
    asSwal?: boolean;
    disabled?: boolean;
    external?: boolean;
    fluid?: boolean;
    withoutRadius?: boolean;
    className?: string;
    color?: ButtonColor;
    href?: string;
    startIcon?: React.ReactNode;
    type?: 'icon-rounded';
    shape?: 'round' | 'square' | 'rounded' | 'oval';
    size?: 'small' | 'medium' | 'big' | 'biggest';
    selectionClassName?: string;
    iconClassName?: string;
    swalOptions?: Record<string, unknown>;
    swalCallback?: (isConfirm: boolean, swal: unknown) => Promise<void>;
    onClick?: (event: SyntheticEvent) => void;
    children: ReactNode;
}

const Button = forwardRef<any, Props>((props, ref) => {
    const {
        asSwal,
        disabled,
        external,
        fluid,
        withoutRadius,
        children,
        className,
        color = 'default',
        shape = 'round',
        href,
        size = 'medium',
        startIcon,
        swalOptions,
        selectionClassName,
        iconClassName,
        type,
        onClick,
        swalCallback,
    } = props;

    const content = useMemo(
        () => (
            <>
                {type === 'icon-rounded' && <div className={cn(st.selection, selectionClassName)} />}
                {startIcon && <span className={cn(st.iconContainer, iconClassName)}>{startIcon}</span>}
                {children}
            </>
        ),
        [type, startIcon, children, selectionClassName, iconClassName],
    );

    const mainClasses = cn(
        st.button,
        st[`color-${color}`],
        st[`size-${size}`],
        st[`shape-${shape}`],
        {
            [st.fluid]: fluid,
            [st[`type-${type}`]]: type,
            [st.disabled]: disabled,
            [st.withoutRadius]: withoutRadius,
        },
        className,
        'component-button-event',
    );

    if (external && !disabled) {
        return (
            <a
                ref={ref}
                className={mainClasses}
                href={href}
                rel="noopener noreferrer"
                target="_blank"
                onClick={onClick}
            >
                {content}
            </a>
        );
    }

    if (asSwal) {
        return (
            <Swal className={mainClasses} options={swalOptions} callback={swalCallback}>
                {content}
            </Swal>
        );
    }

    if (href && !external && !disabled) {
        return (
            <Link ref={ref} className={mainClasses} to={href} onClick={onClick}>
                {content}
            </Link>
        );
    }

    return (
        <button ref={ref} type="button" disabled={disabled} className={mainClasses} onClick={onClick}>
            {content}
        </button>
    );
});

Button.displayName = 'Button';
export default Button;
