import type { RecordByEnv } from '../types';

export type Addresses = {
    ORACLE: string;
    DUCK_INCUBATOR_DAPP: string;
    TURTLES_INCUBATOR_DAPP: string;
    AUCTION_DAPP: string;
    COUPONS_DAPP: string;
    DUCK_BREEDER_DAPP: string;
    TURTLES_BREEDER_DAPP: string;
    DUCK_FARMING_DAPP: string;
    LEGACY_FARMING_DAPP?: string;
    VEGG_FARMING_DAPP: string;
    TURTLES_FARMING_DAPP: string;
    REFERRAL_DAPP: string;
    DUCK_REBIRTH_DAPP: string;
    TURTLE_REBIRTH_DAPP: string;
    CANINE_REBIRTH_DAPP: string;
    FELINE_REBIRTH_DAPP: string;
    BABY_DUCKS_DAPP: string;
    BREEDING_BABY_DUCKS_DAPP: string;
    GAME_DAPP: string;
    HUNT_DAPP: string;
    LOOT_BOXES_DAPP: string;
    ACCOUNT_BOOSTER_DAPP: string;
    MEGA_DUCK_HOUSE_DAPP: string;
    DUCK_HOUSE_DAPP: string;
    LEGACY_MEGA_DUCK_HOUSE_DAPP?: string;
    LEGACY_DUCK_HOUSE_DAPP?: string;
    XMAS_STBLE_DAPP: string;
    WEARABLES_DAPP: string;
    COLLECTIVE_FARMS_MASTER: string;
    MARKETPLACE_PROXY: string;
    SWAP_DAPP?: string;
    SWOP_FI_EGG_WAVES_DAPP?: string;
    SWOP_DAPP?: string;
    SPENCER_PROXY_DAPP?: string;
    PUZZLE_POOLS: string[];
    SDUCK_DAPP?: string;
    ROPE_EGG_DAPP: string;
    DUCK_CAPACITY_DAPP: string;
    SKILL_TREE_DAPP: string;
    HUNT_TRANSFER_ADDRESS: string;
    BURN_DAPP: string;
    RENT_DAPP_ADDRESS: string;
    AMM_DAPP_LAYER_2_ADDRESS: string;
    PETE_DAPP_ADDRESS: string;
    CF_AMM_DAPP: string;
    PETE_POOL_DAPP: string;
    NATIVE_POOL_DAPP: string;
    CF_POOL_DAPP: string;
    MUTANTS_BREEDER_DAPP: string;
    MUTANT_FARMING_DAPP: string;
    LEASING_ADDRESS: string;
    CANINES_BREEDER_DAPP: string;
    FELINES_BREEDER_DAPP: string;
    CANINES_INCUBATOR_DAPP: string;
    FELINES_INCUBATOR_DAPP: string;
    CANINES_FARMING_DAPP: string;
    FELINES_FARMING_DAPP: string;
};

const ADDRESS: RecordByEnv<Addresses> = {
    DEVELOPMENT: {
        ORACLE: '3MxZNnLG9EBcb4yExxNwcFq9vcyMb7DcGT9',
        DUCK_INCUBATOR_DAPP: '3N6oB7VbceD3NoguGkfGnBMF4hcHZVbK8io',
        TURTLES_INCUBATOR_DAPP: '3MvD6ypLdYhx8WBqi2FjCr3GqrncFiZWDbq',
        CANINES_INCUBATOR_DAPP: '3MtrXLq2YFHNLqTBXr488vaQVQpsHdmbg7C',
        FELINES_INCUBATOR_DAPP: '3MwaAkx6seuusecHhgs3DU2tz7GUqPPA8dL',
        DUCK_BREEDER_DAPP: '3NCe8d95V7tfMPXn21c9r368XtTUYU3M2q2',
        TURTLES_BREEDER_DAPP: '3N5LaqMhtroXEqtz3nUc4QFZuPsq85MH2RU',
        MUTANTS_BREEDER_DAPP: '3N1mAvAyeEwnFekyjaY4BXBKUcHqAUxwQxX',
        CANINES_BREEDER_DAPP: '3MzBKfvEdHEdy5GmoTxAfLfM1mTafCbaNWv',
        FELINES_BREEDER_DAPP: '3N3E5DdENzNsTDLRTChV21RMKbtVJmoyJwe',
        DUCK_FARMING_DAPP: '3N33m3JMhEN5QxhDKujcsbpFHo3UcHE4Jwt',
        VEGG_FARMING_DAPP: '3N8uhPJynHpAKrQUsZCQHN3oLTgxPr3kao2',
        TURTLES_FARMING_DAPP: '3MvqNgAQonu6nSGGh71ohTyBPhzdJxPK7QA',
        CANINES_FARMING_DAPP: '3NAN9Bp8r6PqgX6VJhNFyoKjHEH8N7bGvBQ',
        FELINES_FARMING_DAPP: '3N5ZQQQtXgjvyN5iYK8kxZsS8eUYrnhcb3T',
        DUCK_REBIRTH_DAPP: '3N6PvAL6whpaLE48xjeZbmU7vRWQmuz8AA5',
        TURTLE_REBIRTH_DAPP: '3Mrq917T9DgxmZHP2wBRURvA2jUseEGaMqW',
        CANINE_REBIRTH_DAPP: '3Mw8EHDvHCbzm6VE777G6EJNQBEe6CeS335',
        FELINE_REBIRTH_DAPP: '3N26gCrijWPWiTMtJnMTr6w7aYjTHvFrb9W',
        BABY_DUCKS_DAPP: '3MpLKSQezEyHJk8jn9ikzAaaGuRzy5STBLZ',
        BREEDING_BABY_DUCKS_DAPP: '3N5UweaCwk6K5PZ2g8nfVuK4zDnpayfoHLD',
        AUCTION_DAPP: '3N3Ctnjg7Fd3TXkwJFNg8eCbWZn9E1avQwS',
        COUPONS_DAPP: '3MwFCBSWkWf9JMqmRyN5WEdoF2MCEPUB1Jx',
        REFERRAL_DAPP: '3MtCto83UgaYLqkeqUVj3apiBt6YzTHgTsf',
        GAME_DAPP: '3N3ow28aEESPBAtyxhjWAyRhpMaXSTLcy33',
        HUNT_DAPP: '3MzYJ5tJs1rdz6Pzr7zvyfezTLPuVxFSRvT',
        HUNT_TRANSFER_ADDRESS: '',
        PUZZLE_POOLS: ['3MwFjFBcnarwx5dRetVMDb9UYwqGcpNE9Qm', '3N4XqrBrauR8vxrs7o6vmHW3z4X16V5FEG6'],
        LOOT_BOXES_DAPP: '3N1ZfechNGoBTZyzvCAxGXPKchL4C6438dr',
        WEARABLES_DAPP: '3N1oZwpYE3p4x63jWiKpszmfLU3Lk3KtSWe',
        ACCOUNT_BOOSTER_DAPP: '3NA6gug1TT67nGzrAGa36rG5nzTzUGtFQG9',
        MUTANT_FARMING_DAPP: '3N1y6kVT6VXz9mGb7seF2wAQ2dMKG1ojx2H',
        MEGA_DUCK_HOUSE_DAPP: '3MunHm9yAJrfSpj9C4NAdWUXFm4kY5r9HgK',
        DUCK_HOUSE_DAPP: '3MpLnByEzrxP1Qkvy9BM1wrc9vmnmYbhdDY',
        XMAS_STBLE_DAPP: '3NCyT9Mj2u1Xmwb2PHUJDm9sTFA7U1t4R9J',
        MARKETPLACE_PROXY: '3N1MBCmgtCtNvJotZJ66hukSqdMtFpomvXr',
        ROPE_EGG_DAPP: '3MsPYUm9723jTqtBwkhTaPZam3cmZKipw1k',
        DUCK_CAPACITY_DAPP: '3MxVJox4gUBhTYKrzK458PLEwbnhsijAihN',
        SKILL_TREE_DAPP: '3N2iKmAK2hjZiqPD459iBpCfpoLLAiTu2Jz',
        BURN_DAPP: '3Mp8MKhxBFzzBhS6txZgmaD7xvsbZgLuhjB',
        RENT_DAPP_ADDRESS: '', // Erase this
        AMM_DAPP_LAYER_2_ADDRESS: '3MzT6Lr8UBLvCnz1rU1KeRc5xws5SSGiFpT',
        CF_AMM_DAPP: '',
        PETE_POOL_DAPP: '3NAxFRdHPdjEy2RNC7Y6EmjuUXRhD2KXC9N',
        NATIVE_POOL_DAPP: '3N28mjf56ZDzhGpFQVgFTuA6U6snixN1F4F',
        CF_POOL_DAPP: '3MzHdX1Ph87dTGHUuucyGnaxoK2fZ9UJpUn',
        PETE_DAPP_ADDRESS: '3N2PjXqef29HexCE7PeGZa9eRjS2jVeaFe4',
        LEASING_ADDRESS: '3Mx9X6Dpj3thXL2So77C4DG9AmBM4WX8Mvw',
        COLLECTIVE_FARMS_MASTER: '3N6hCjxTRqj7PpGbaiUn8XjUoUMFVX8Coaz',
    },
    PRODUCTION: {
        ORACLE: '3PJgZ6AK1WVCpdCmEZpesHmaKvrQDdXG5og',
        DUCK_INCUBATOR_DAPP: '3PEktVux2RhchSN63DsDo4b4mz4QqzKSeDv',
        TURTLES_INCUBATOR_DAPP: '3PE9yT1qVM9hsRkpiV6Y7AKEubafyT5NCuB',
        CANINES_INCUBATOR_DAPP: '3PD6L4yr52Lac9qeqXNoi4mAiiak5ZBnx5D',
        FELINES_INCUBATOR_DAPP: '3P9SJwLFhgRQG5jAQinzZFyPzE9cP3oyu8Q',
        DUCK_BREEDER_DAPP: '3PDVuU45H7Eh5dmtNbnRNRStGwULA7NY6Hb',
        TURTLES_BREEDER_DAPP: '3P97G4z2qH2pZGPYwWw47pFQu7cpyjR579t',
        MUTANTS_BREEDER_DAPP: '3PJr8TE2b8bEmPXXoHcJnPanke3Yi41zzya',
        CANINES_BREEDER_DAPP: '3PQdwhdjWHZ1hacy2dLPnRafi86cTvuJPNX',
        FELINES_BREEDER_DAPP: '3PKhghDw2necJLA6mGuQuC4juWzmu7Subu2',
        DUCK_FARMING_DAPP: '3PEpQ4LaeZA6DVMyrgU3PTFBprAF4tRziBD',
        TURTLES_FARMING_DAPP: '3PG1QrkrsZaEpo8Bhe34geNNA1d9M8REGrv',
        MUTANT_FARMING_DAPP: '3PFDP2upzDE7Djhhey5yweAQqWsMcoAzaUc',
        LEGACY_FARMING_DAPP: '3PAETTtuW7aSiyKtn9GuML3RgtV1xdq1mQW',
        VEGG_FARMING_DAPP: '3P7s9drVRL4Kw3edKv5RhNd5Qrwxu1cU3d3',
        CANINES_FARMING_DAPP: '3PCBQJP8XEK5sgziA9pu3AHKnegmBxxojaY',
        FELINES_FARMING_DAPP: '3P6KR6QtXmPH8onFKMe276bVKUNVRzmNGSR',
        DUCK_REBIRTH_DAPP: '3PCC6fVHNa6289DTDmcUo3RuLaFmteZZsmQ',
        TURTLE_REBIRTH_DAPP: '3PM7TWS85ZupzH1k5wYQa4HPJjEn95j5N7P',
        CANINE_REBIRTH_DAPP: '3P4Anrwd7rKDGyxXZRaocXVbKtg8TKEzf6D',
        FELINE_REBIRTH_DAPP: '3PMUJt5Voyxy12DjsKVKtFctA4HpAEfSgc5',
        BABY_DUCKS_DAPP: '3PKmLiGEfqLWMC1H9xhzqvAZKUXfFm8uoeg',
        REFERRAL_DAPP: '3P8ejTkfRpz9WqCwCuihesNXU5k3zmFFfVe',
        AUCTION_DAPP: '3PEBtiSVLrqyYxGd76vXKu8FFWWsD1c5uYG',
        COUPONS_DAPP: '3P5W1D4UuSZrNfeZFJZcD5wT2avfbHbzTvS',
        BREEDING_BABY_DUCKS_DAPP: '3PKdgEHMR7HzTQdXkHqTPRUWtBXhYF1NihS',
        GAME_DAPP: '3PR87TwfWio6HVUScSaHGMnFYkGyaVdFeqT',
        HUNT_DAPP: '3P3Gtbq2raMqBkks8tr6sTZH9uHCoVTBfQ8',
        LOOT_BOXES_DAPP: '3P5E9xamcWoymiqLx8ZdmR7o4fJSRMGp1WR',
        WEARABLES_DAPP: '3PCxXZk5r9nL8ppcqRjDAqeYWzRQSDapxWw',
        ACCOUNT_BOOSTER_DAPP: '3PNbBGH1omxP5yMewxvDcuLrb4RAKgKt1uD',
        MEGA_DUCK_HOUSE_DAPP: '3PGwjnnoPreHxeRDxufGD8a2iqaeM5tQKno',
        DUCK_HOUSE_DAPP: '3P8JD7RpyGZeVGj58P2ox367iMwsM3hWosS',
        LEGACY_MEGA_DUCK_HOUSE_DAPP: '3PFfetFUtrnxjv8Q66t9U5fqLFbyoFxzCQo',
        LEGACY_DUCK_HOUSE_DAPP: '3PDbviVyp8vGmCnnjf2rHT4fpUMYe8XtgSL',
        XMAS_STBLE_DAPP: '3PAehH4ZP62QEj4swakf6FU9CLaVvY3qddh',
        COLLECTIVE_FARMS_MASTER: '3PDktsxDVEcoobpEBJcausnKo4enhcWUDEF',
        MARKETPLACE_PROXY: '3PL1tnAkQUP3jQNGUu2NvfAuWuz5kqrhctt',
        SWAP_DAPP: '3PJQUUiJdvz9etUKED9ju7o7VrcNMtnkXBU',
        SWOP_FI_EGG_WAVES_DAPP: '3PNVFWopwCD9CgGXkpYWEY94oQ5XCAEXBmQ',
        SWOP_DAPP: '3PCUJBpMXtqYKxMiDtKcTeEQcKukfMeoctR',
        SPENCER_PROXY_DAPP: '3PBroEophsemuK1NEEBYAGVM8Q8JaKUCytH',
        PUZZLE_POOLS: ['3PPRHHF9JKvDLkAc3aHD3Kd5tRZp1CoqAJa', '3PKYPKJPHZENAAwH9e7TF5edDgukNxxBt3M'],
        SDUCK_DAPP: '3P3pDosq4GCwfJkvq4yqKvvoTwmoqc9qPmo',
        ROPE_EGG_DAPP: '3P6dr3R6kKCsiRd1ebPx8XM4r1qdmUyHAN8',
        DUCK_CAPACITY_DAPP: '3P472BzRvamyTwGWD2os6oQNzvy8FYt7uSG',
        SKILL_TREE_DAPP: '3P5gckRPNh1ve5mZS4SkFY3T8pYqaApjqCi',
        HUNT_TRANSFER_ADDRESS: '3P7eZotfwNFYWGm9YeWhTZed1JdLdiszuh7',
        BURN_DAPP: '3PFq5vswexHUS15BXYaKMXtTDVhkUfPXoG2',
        RENT_DAPP_ADDRESS: '3PEgzEYXMbAHU4ZuAPrd6HanY4LW4Ee5J6F',
        AMM_DAPP_LAYER_2_ADDRESS: '3P4me2DmXjXs15zkcUGKrG4KdyFxQbLxNZu',
        PETE_DAPP_ADDRESS: '3PBf2jd3ZEPX7NHQvVarscTjLQ8bk43uAfn',
        CF_AMM_DAPP: '3P2A54fWwUktfGVDpNdGaDPdgGbfA3LR1aV',
        PETE_POOL_DAPP: '',
        CF_POOL_DAPP: '',
        NATIVE_POOL_DAPP: '',
        LEASING_ADDRESS: '3PA1KvFfq9VuJjg45p2ytGgaNjrgnLSgf4r',
    },
    TEST: {
        ORACLE: '3MxZNnLG9EBcb4yExxNwcFq9vcyMb7DcGT9',
        DUCK_INCUBATOR_DAPP: '3N6oB7VbceD3NoguGkfGnBMF4hcHZVbK8io',
        TURTLES_INCUBATOR_DAPP: '3MvD6ypLdYhx8WBqi2FjCr3GqrncFiZWDbq',
        CANINES_INCUBATOR_DAPP: '3MtrXLq2YFHNLqTBXr488vaQVQpsHdmbg7C',
        FELINES_INCUBATOR_DAPP: '3MwaAkx6seuusecHhgs3DU2tz7GUqPPA8dL',
        DUCK_BREEDER_DAPP: '3NCe8d95V7tfMPXn21c9r368XtTUYU3M2q2',
        TURTLES_BREEDER_DAPP: '3N5LaqMhtroXEqtz3nUc4QFZuPsq85MH2RU',
        MUTANTS_BREEDER_DAPP: '3N1mAvAyeEwnFekyjaY4BXBKUcHqAUxwQxX',
        CANINES_BREEDER_DAPP: '3MzBKfvEdHEdy5GmoTxAfLfM1mTafCbaNWv',
        FELINES_BREEDER_DAPP: '3N3E5DdENzNsTDLRTChV21RMKbtVJmoyJwe',
        DUCK_FARMING_DAPP: '3N33m3JMhEN5QxhDKujcsbpFHo3UcHE4Jwt',
        VEGG_FARMING_DAPP: '3N8uhPJynHpAKrQUsZCQHN3oLTgxPr3kao2',
        TURTLES_FARMING_DAPP: '3MvqNgAQonu6nSGGh71ohTyBPhzdJxPK7QA',
        CANINES_FARMING_DAPP: '3NAN9Bp8r6PqgX6VJhNFyoKjHEH8N7bGvBQ',
        FELINES_FARMING_DAPP: '3N5ZQQQtXgjvyN5iYK8kxZsS8eUYrnhcb3T',
        DUCK_REBIRTH_DAPP: '3N6PvAL6whpaLE48xjeZbmU7vRWQmuz8AA5',
        TURTLE_REBIRTH_DAPP: '3Mrq917T9DgxmZHP2wBRURvA2jUseEGaMqW',
        CANINE_REBIRTH_DAPP: '3Mw8EHDvHCbzm6VE777G6EJNQBEe6CeS335',
        FELINE_REBIRTH_DAPP: '3N26gCrijWPWiTMtJnMTr6w7aYjTHvFrb9W',
        BABY_DUCKS_DAPP: '3MpLKSQezEyHJk8jn9ikzAaaGuRzy5STBLZ',
        BREEDING_BABY_DUCKS_DAPP: '3N5UweaCwk6K5PZ2g8nfVuK4zDnpayfoHLD',
        AUCTION_DAPP: '3N3Ctnjg7Fd3TXkwJFNg8eCbWZn9E1avQwS',
        COUPONS_DAPP: '3MwFCBSWkWf9JMqmRyN5WEdoF2MCEPUB1Jx',
        REFERRAL_DAPP: '3MtCto83UgaYLqkeqUVj3apiBt6YzTHgTsf',
        GAME_DAPP: '3N3ow28aEESPBAtyxhjWAyRhpMaXSTLcy33',
        HUNT_DAPP: '3MzYJ5tJs1rdz6Pzr7zvyfezTLPuVxFSRvT',
        HUNT_TRANSFER_ADDRESS: '',
        PUZZLE_POOLS: ['3MwFjFBcnarwx5dRetVMDb9UYwqGcpNE9Qm', '3N4XqrBrauR8vxrs7o6vmHW3z4X16V5FEG6'],
        LOOT_BOXES_DAPP: '3N1ZfechNGoBTZyzvCAxGXPKchL4C6438dr',
        WEARABLES_DAPP: '3N1oZwpYE3p4x63jWiKpszmfLU3Lk3KtSWe',
        ACCOUNT_BOOSTER_DAPP: '3NA6gug1TT67nGzrAGa36rG5nzTzUGtFQG9',
        MUTANT_FARMING_DAPP: '3N1y6kVT6VXz9mGb7seF2wAQ2dMKG1ojx2H',
        MEGA_DUCK_HOUSE_DAPP: '3MunHm9yAJrfSpj9C4NAdWUXFm4kY5r9HgK',
        DUCK_HOUSE_DAPP: '3MpLnByEzrxP1Qkvy9BM1wrc9vmnmYbhdDY',
        XMAS_STBLE_DAPP: '3NCyT9Mj2u1Xmwb2PHUJDm9sTFA7U1t4R9J',
        MARKETPLACE_PROXY: '3N1MBCmgtCtNvJotZJ66hukSqdMtFpomvXr',
        ROPE_EGG_DAPP: '3MsPYUm9723jTqtBwkhTaPZam3cmZKipw1k',
        DUCK_CAPACITY_DAPP: '3MxVJox4gUBhTYKrzK458PLEwbnhsijAihN',
        SKILL_TREE_DAPP: '3N2iKmAK2hjZiqPD459iBpCfpoLLAiTu2Jz',
        BURN_DAPP: '3Mp8MKhxBFzzBhS6txZgmaD7xvsbZgLuhjB',
        RENT_DAPP_ADDRESS: '', // Erase this
        AMM_DAPP_LAYER_2_ADDRESS: '3MzT6Lr8UBLvCnz1rU1KeRc5xws5SSGiFpT',
        CF_AMM_DAPP: '',
        PETE_POOL_DAPP: '3NAxFRdHPdjEy2RNC7Y6EmjuUXRhD2KXC9N',
        NATIVE_POOL_DAPP: '3N28mjf56ZDzhGpFQVgFTuA6U6snixN1F4F',
        CF_POOL_DAPP: '3MzHdX1Ph87dTGHUuucyGnaxoK2fZ9UJpUn',
        LEASING_ADDRESS: '3Mx9X6Dpj3thXL2So77C4DG9AmBM4WX8Mvw',
        PETE_DAPP_ADDRESS: '3N2PjXqef29HexCE7PeGZa9eRjS2jVeaFe4',
        COLLECTIVE_FARMS_MASTER: '3N6hCjxTRqj7PpGbaiUn8XjUoUMFVX8Coaz',
    },
};

export default ADDRESS;
