export enum ARTEFACT_TYPE {
    'LAKE' = 'ART-LAKE',
    'HOUSE' = 'ART-HOUSE',
    'BIGHOUSE' = 'ART-BIGHOUSE',
    'XMAS-STBLE' = 'ART-XMAS_STBLE',
    'FIXGENE' = 'ART-FIXGENE',
    'FREEGENE' = 'ART-FREEGENE',
    'MIRROR' = 'ART-MIRROR',
    'CUSTOMDUCK' = 'ART-CUSTOMDUCK',
    'POMP' = 'ART-POMP',
    'CAPE' = 'ART-CAPE',
    'HAT' = 'ART-HAT',
    'XMISTL' = 'ART-XMISTL',
    'XHAT' = 'ART-XHAT',
    'XSCARF' = 'ART-XSCARF',
    'XSWEATER' = 'ART-XSWEATER',
    'XSOCK' = 'ART-XSOCK',
    'XTREE' = 'ART-XTREE',
    'FEED5' = 'ART-FEED5',
    'FEED10' = 'ART-FEED10',
    'FEED15' = 'ART-FEED15',
    'FEED20' = 'ART-FEED20',
    'FEED25' = 'ART-FEED25',
    'FEED50' = 'ART-FEED50',
    'FEED100' = 'ART-FEED100',
    'FEED500' = 'ART-FEED500',
    'CURE' = 'ART-CURE',
    'CUST-COSM' = 'ART-CUST-COSM',
    'ITEM-DUPLI' = 'ART-ITEM_DUPLI',
    'PETE' = 'ART-PETE',
    'SNW-GLBES' = 'ART-SNW_GLBES',
    'TUXEDO' = 'ART-TUXEDO',
    'GIFT-DOUBLE' = 'ART-GIFT_DOUBL',
    'MUTARIUM-D' = 'ART-MUTARIUM-D',
    'MUTARIUM-G' = 'ART-MUTARIUM-G',
    'POTION' = 'ART-POTION',
    'SKELHEAD' = 'ART-SKELHEAD',
    'BONE' = 'ART-BONE',
}

export enum ITEM_GROUP_TYPE {
    BACK = 'BACK',
    HEAD = 'HEAD',
    HELMET = 'HELMET',
    MASK = 'MASK',
    ACCESSORY = 'ACCESSORY',
    LEFT_WING = 'LEFT_WING',
    RIGHT_WING = 'RIGHT_WING',
    TOP = 'TOP',
    TAIL = 'TAIL',
    PET = 'PET',
    CONSUMABLE = 'CONSUMABLE',
}
