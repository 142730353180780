import useFeatureFlag from './useFeatureFlag';

const useEventStyles = async (): Promise<{ [className: string]: string }> => {
    const isHalloweenEnabled = useFeatureFlag('HALLOWEEN_ENABLED');

    let styles;
    if (isHalloweenEnabled) {
        styles = await import('$/styles/app/event-layouts/halloweenLayout.scss');
    }
    return styles?.default || {};
};

export const useEventBackgroundHasAnimations = (): boolean => {
    const isHalloweenEnabled = useFeatureFlag('HALLOWEEN_ENABLED');

    return isHalloweenEnabled; // add new animation events here
};

export default useEventStyles;
